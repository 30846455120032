import * as React from "react"

function NavSearchSvg(props) {
	return (
		<svg
			viewBox="0 0 30 30"
			xmlns="http://www.w3.org/2000/svg"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeLinecap="round"
			strokeMiterlimit={10}
			{...props}
		>
			<g transform="translate(2.75 2.75)">
				<ellipse
					cx={9.5}
					cy={10}
					rx={9.5}
					ry={10}
					fill="none"
					stroke="#003287"
					strokeWidth={4}
				/>
				<path d="M17 18l7.5 6.5" fill="none" stroke="#003287" strokeWidth={4} />
			</g>
		</svg>
	)
}

export default NavSearchSvg
