import React, { useState, useEffect } from 'react'
import TabPanelComponent from '../../../components/TabPanelComponent';
import useTheme from "@material-ui/core/styles/useTheme";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";

import ServiceTabContentComponent from './ServiceTabContentComponent';
import EqSelectorComponent from '../../../components/EqSelectorComponent';
import ServiceSkeletonComponent from '../../../components/skeletons/ServiceSkeletonComponent';

const ServiceTabComponent = ({ items, value, startTabIndex = 0, index, isService, children }) => {
    const [types, setTypes] = useState('0');
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    useEffect(() => {
        if (items?.length > 1) {
            setTypes(startTabIndex)
        }
    }, [items, startTabIndex])

    return (
        <TabPanelComponent value={value} index={index} small spacing={0}>
            <Grid item xs={12}>
                {items?.length
                    ? items.map((c, i) =>
                        <div key={i} hidden={types !== `${i}`}>
                            <Box display={matches ? 'block' : 'flex'} flexWrap='wrap' alignItems='top' className='services-tab'>
                                <ServiceTabContentComponent
                                    lazy={i !== parseInt(startTabIndex)}
                                    types={types}
                                    data={isService ? c.services : c.categories}
                                />
                            </Box>
                        </div>
                    )
                    : <Grid container>
                        <ServiceSkeletonComponent />
                        <ServiceSkeletonComponent />
                        <ServiceSkeletonComponent />
                        <ServiceSkeletonComponent />
                        <ServiceSkeletonComponent />
                        <ServiceSkeletonComponent />
                        <ServiceSkeletonComponent />
                        <ServiceSkeletonComponent />
                    </Grid>}
            </Grid>
            {items?.length > 1
                ? <EqSelectorComponent md={12} items={items?.length ? items.map(c => c.name) : []} type={types} setType={setTypes} />
                : <Box height={65.5} width='100%'></Box>
            }

        </TabPanelComponent>
    )
}


export default React.memo(ServiceTabComponent);