import React from 'react';
import LogoImg from '../imgs/logo512.jpg'

const LoaderComponent = (props) => {
    return (
        <div className={'container-render-loader'}>
            <img src={LogoImg} className="first-element" alt="Rami logo" />
        </div>
    )
}


export default React.memo(LoaderComponent);