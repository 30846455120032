import { API_ABOUT } from '../constants/api';
import { GET } from '../functions/superagentSending';


const initialState = {
    loading: false,
    data: {},
    error: '',
};


const FETCH_CERTIFICATIONS_REQUEST = 'FETCH_CERTIFICATIONS_REQUEST'
const FETCH_CERTIFICATIONS_SUCCESS = 'FETCH_CERTIFICATIONS_SUCCESS'
const FETCH_CERTIFICATIONS_FAILURE = 'FETCH_CERTIFICATIONS_FAILURE'

const fetchCertificationsRequest = () => ({
    type: FETCH_CERTIFICATIONS_REQUEST
})

const fetchCertificationsSuccess = data => ({
    type: FETCH_CERTIFICATIONS_SUCCESS,
    payload: data
})

const fetchCertificationsFailure = error => ({
    type: FETCH_CERTIFICATIONS_FAILURE,
    payload: error
})

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_CERTIFICATIONS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_CERTIFICATIONS_SUCCESS:
            return {
                loading: false,
                data: payload,
                error: '',
            }
        case FETCH_CERTIFICATIONS_FAILURE:
            return {
                loading: false,
                data: {},
                error: payload
            }
        default:
            return state
    }
}

export const FETCH_CERTIFICATIONS_DATA_REDUCER = () => {
    return function (dispatch) {
        dispatch(fetchCertificationsRequest())
        GET(API_ABOUT)
            .then(res => {
                dispatch(fetchCertificationsSuccess(res.body))
            })
            .catch(error => {
                dispatch(fetchCertificationsFailure(error))
                dispatch(fetchCertificationsFailure(''))
            })
    }
}
