import React from "react";
import { Box, Typography, makeStyles, Container } from "@material-ui/core";
// import RouteLinkComponent from "./RouteLinkComponent";
import { useTranslation } from "react-i18next";

import loadable from '@loadable/component'
import { SMALL_HEIGHT_DEVICES } from "../constants";
import ButtonComponent from "./ButtonComponent";
const RouteLinkComponent = loadable(() => import('./RouteLinkComponent'))
// const ButtonComponent = loadable(() => import('./ButtonComponent'))

const useStyles = makeStyles((theme) => ({
  headerTextWrapper: {
    position: "absolute",
    top: 40,
    left: "20%",
    transform: `translate(-20%, 0)`,
    width: "100%",
    [SMALL_HEIGHT_DEVICES]: {
      top: 50,
    },
    [theme.breakpoints.down("sm")]: {
      top: 50,
    },
    [theme.breakpoints.down("xs")]: {
      top: 50,
    },
  },
  container: {
    [theme.breakpoints.down("xs")]: {
      left: "25%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  titleHeader: {
    fontSize: 60,
    lineHeight: 1.2,
    textShadow: "0 0 10px rgba(0,0,0,0.9)",
    fontWeight: 700,
    letterSpacing: 0,
    color: theme.palette.white.main,
    [SMALL_HEIGHT_DEVICES]: {
      fontSize: 45,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 30,
    },

    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },

  },
  content: {
    marginTop: 5,
    fontSize: 24,
    lineHeight: 1.2,
    textShadow: "0 0 10px rgba(0,0,0,0.9)",
    fontWeight: 700,
    color: theme.palette.white.main,
    [SMALL_HEIGHT_DEVICES]: {
      fontSize: 16,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      '& p': {
        marginTop: 4,
        marginBottom: 4,
      }
    },
  },
  box: {
    marginBottom: 20,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 15,
    },
  },
  btn: (props) => ({
    // '&.MuiButton-containedSecondary': {
    color: theme.palette.white.main,
    backgroundColor: props.btnColor
      ? props.btnColor
      : theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.orange,
    },
    // },
  }),
}));

const BannerContentComponent = ({ text, href, to, content, btnColor }) => {
  const classes = useStyles({ btnColor });
  const { t } = useTranslation();
  return (
    <Box className={classes.headerTextWrapper}>
      <Container className={classes.container}>
        <Box className={classes.box}>
          <Typography
            variant="h2"
            component="h2"
            className={classes.titleHeader}
            dangerouslySetInnerHTML={{ __html: text }}
          />
          <Typography
            className={classes.content}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </Box>
        <RouteLinkComponent href={href} to={to}>
          <ButtonComponent variant="contained" className={classes.btn}>
            {t("buttons.more")}
          </ButtonComponent>
        </RouteLinkComponent>
      </Container>
    </Box>
  );
};

export default React.memo(BannerContentComponent);
