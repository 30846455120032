
const initialState = {
    slideDocColor: '',
};


const SET_MAIN_SUCCESS = 'SET_MAIN_SUCCESS'

const setMainSuccess = (id, data) => ({
    type: SET_MAIN_SUCCESS,
    payload: { id, data}
})

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_MAIN_SUCCESS:
            return {
                ...state,
                [payload.id]: payload.data
            }
        default:
            return state
    }
}

export const SET_MAIN_REDUCER = (id, data) => {
    return function (dispatch) {
        dispatch(setMainSuccess(id, data))
    }
}
