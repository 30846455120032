import { API_MAIN, API_RAMI_MAIN } from '../constants/api';
import { GET, GET_RAMI } from '../functions/superagentSending';
import updateTime from '../functions/updateTime';
import { FETCH_CONTACT_REDUCER } from './ContactReducer';


const initialState = {
    loading: false,
    data: {
        banner: [],
        blog: [],
        sevices: [],
        our_suppliers: [],
        ramiError: ''
    },
    error: '',
};


const FETCH_DATA_REQUEST = 'FETCH_DATA_REQUEST'
const FETCH_DATA_REQUEST_SUCCESS = 'FETCH_DATA_REQUEST_SUCCESS'
const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS'
const FETCH_DATA_FAILURE = 'FETCH_DATA_FAILURE'

const fetchDataRequest = () => ({
    type: FETCH_DATA_REQUEST
})

const fetchDataSuccess = data => ({
    type: FETCH_DATA_SUCCESS,
    payload: data
})
const fetchDataRequestSuccess = data => ({
    type: FETCH_DATA_REQUEST_SUCCESS,
    payload: data
})

const fetchDataFailure = error => ({
    type: FETCH_DATA_FAILURE,
    payload: error
})

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_DATA_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_DATA_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case FETCH_DATA_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...payload,
                },
                error: '',
            }
        case FETCH_DATA_FAILURE:
            return {
                loading: false,
                data: {},
                error: payload
            }
        default:
            return state
    }
}

const CHECK_ENDPOINT = (endpoint) => (dispatch) => {
    if (endpoint === 2) {
        dispatch(fetchDataRequestSuccess());
    }
}

export const FETCH_HOME_DATA_REDUCER = () => {
    return (dispatch) => {
        let endpoint = 0;
        dispatch(fetchDataRequest())
        const ramirent = localStorage.getItem('ramirentMain');
        dispatch(CHECK_ENDPOINT(endpoint));
        const ramionline = localStorage.getItem('ramionline');
        const isCacheTime = updateTime(localStorage.getItem('updateRamionlineDate'))

        if (isCacheTime && ramirent) {
            dispatch(fetchDataSuccess(JSON.parse(ramirent)))
            endpoint += 1;
        } else {
            GET(API_MAIN)
                .then((res) => {
                    dispatch(fetchDataSuccess(res.body.data))
                    localStorage.setItem('ramirentMain', JSON.stringify(res.body.data));
                    endpoint += 1;
                    dispatch(CHECK_ENDPOINT(endpoint));
                })
                .catch((error) => {
                    dispatch(fetchDataFailure(error))
                    dispatch(fetchDataFailure(''))
                })
        }
        if (isCacheTime && ramionline) {
            const data = JSON.parse(ramionline);
            dispatch(FETCH_CONTACT_REDUCER(data[4]))
            dispatch(fetchDataSuccess({
                ...data.reduce((result, item, index, array) => {
                    result[item?.type || index] = item.data; //a, b, c
                    return result;
                }, {}),
                ramiError: '',
            }))
            endpoint += 1;
            dispatch(CHECK_ENDPOINT(endpoint));
        } else {
            GET_RAMI(API_RAMI_MAIN)
                .then((res) => {
                    localStorage.setItem('updateRamionlineDate', new Date().getTime())
                    localStorage.setItem('ramionline', JSON.stringify(res.body));
                    dispatch(FETCH_CONTACT_REDUCER(res.body[4]))
                    dispatch(fetchDataSuccess({
                        ...res.body.reduce((result, item, index, array) => {
                            result[item?.type || index] = item.data; //a, b, c
                            return result;
                        }, {}),
                        ramiError: '',
                    }))
                    endpoint += 1;
                    dispatch(CHECK_ENDPOINT(endpoint));
                })
                .catch((error) => {
                    endpoint += 1;
                    dispatch(CHECK_ENDPOINT(endpoint));
                    dispatch(fetchDataSuccess({ ramiError: error }))
                    // dispatch(fetchDataFailure(error))
                    // dispatch(fetchDataFailure(''))
                })
        }
        // Promise.all([GET(API_MAIN), GET_RAMI(API_RAMI_MAIN)])
        //     .then((res) => {
        //         dispatch(FETCH_CONTACT_REDUCER(res[1].body[4]))
        //         dispatch(fetchDataSuccess({
        //             ...res[0].body.data,
        //             ...res[1].body.reduce((result, item, index, array) => {
        //                 result[item?.type || index] = item.data; //a, b, c
        //                 return result;
        //             }, {})
        //         }
        //         ))
        //     })
        //     .catch(error => {

        //     })
    }
}
