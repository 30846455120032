import { API_SEARCH } from '../constants/api';
import { GET_RAMI } from '../functions/superagentSending';


const initialState = {
    loadingAuto: false,
    open: false,
    data: [],
    error: '',
};


const FETCH_SEARCH_REQUEST = 'FETCH_SEARCH_REQUEST'
const FETCH_SEARCH_SUCCESS = 'FETCH_SEARCH_SUCCESS'
const OPEN_SEARCH_SUCCESS = 'OPEN_SEARCH_SUCCESS'
const FETCH_SEARCH_FAILURE = 'FETCH_SEARCH_FAILURE'

const fetchSearchRequest = () => ({
    type: FETCH_SEARCH_REQUEST
})

const fetchSearchSuccess = data => ({
    type: FETCH_SEARCH_SUCCESS,
    payload: data
})
const openSearchSuccess = data => ({
    type: OPEN_SEARCH_SUCCESS,
    payload: data
})

const fetchSearchFailure = error => ({
    type: FETCH_SEARCH_FAILURE,
    payload: error
})

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_SEARCH_REQUEST:
            return {
                ...state,
                loadingAuto: true
            }
        case FETCH_SEARCH_SUCCESS:
            return {
                ...state, 
                loadingAuto: false,
                data: payload || [],
                error: '',
            }
        case OPEN_SEARCH_SUCCESS:
            return {
                ...state,
                open: payload
            }
        case FETCH_SEARCH_FAILURE:
            return {
                ...state,
                loadingAuto: false,
                data: [],
                error: payload,
            }
        default:
            return state
    }
}

export const FETCH_SEARCH_REDUCER = (name) => {
    return function (dispatch) {
        dispatch(fetchSearchRequest())
        GET_RAMI(`${API_SEARCH}/${name}`)
            .then(res => {
                dispatch(fetchSearchSuccess(res.body))
            })
            .catch(error => {
                if (error.status){
                    dispatch(fetchSearchFailure(error))
                    dispatch(fetchSearchFailure(''))
                }
            })
    }
}

export const OPEN_SEARCH_REDUCER = (status) => {
    return function (dispatch) {
        dispatch(openSearchSuccess(status)) 
    }
}
