import { API_MATERIALS } from '../constants/api';
import { GET } from '../functions/superagentSending';


const initialState = {
    loading: false,
    data: [],
    error: '',
};


const FETCH_MATERIALS_REQUEST = 'FETCH_MATERIALS_REQUEST'
const FETCH_MATERIALS_SUCCESS = 'FETCH_MATERIALS_SUCCESS'
const FETCH_MATERIALS_FAILURE = 'FETCH_MATERIALS_FAILURE'

const fetchMaterialsRequest = () => ({
    type: FETCH_MATERIALS_REQUEST
})

const fetchMaterialsSuccess = payload => ({
    type: FETCH_MATERIALS_SUCCESS,
    payload: payload
})

const fetchMaterialsFailure = error => ({
    type: FETCH_MATERIALS_FAILURE,
    payload: error
})

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_MATERIALS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_MATERIALS_SUCCESS:
            return {
                loading: false,
                data: payload,
                error: '',
            }
        case FETCH_MATERIALS_FAILURE:
            return {
                loading: false,
                data: {},
                error: payload
            }
        default:
            return state
    }
}

export const FETCH_MATERIALS_REDUCER = () => {
    return function (dispatch) {
        dispatch(fetchMaterialsRequest())
        GET(API_MATERIALS)
            .then(res => {
                dispatch(fetchMaterialsSuccess(res.body.data))
            })
            .catch(error => {
                dispatch(fetchMaterialsFailure(error))
                dispatch(fetchMaterialsFailure(''))
            })
    }
}
