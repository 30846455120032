import React from 'react'
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';


const useStyles = (small) => makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            padding: 30,
            paddingBottom: small ? 50 : 30,
        },
        [theme.breakpoints.down('xs')]: {
            padding: small ? 0 : 15,
        }
    }
}))


const TabPanelComponent = ({ small, p = 3, justify, spacing = 1, alignItems, children, value, index, ...other }) => {
    const classes = useStyles(small)();
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={p} className={classes.root}>
                    <Grid container spacing={spacing} justify={justify} alignItems={alignItems}>
                        {children}
                    </Grid>
                </Box>
            )}
        </div>
    )
}


export default React.memo(TabPanelComponent);