import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';

const ButtonComponent = withStyles((theme) => ({
    root: {
        borderRadius: '25px',
        fontSize: '13px',
        minWidth: 200,
        fontFamily: '"Boing", "Roboto", "Helvetica", "Arial", "sans-serif"',
        fontWeight: 550,
        padding: '15px 30px',
        boxShadow: 'none',
        '& span': {
            lineHeight: 0.8
        },
        color: theme.palette.text.primary,
        '&:hover': {
            boxShadow: 'none',
        },
    },
}))(Button);


export default ButtonComponent;