import * as React from "react"

function UserSvg(props) {
    return (
        <svg
            viewBox="0 0 148 192"
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            clipRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit={2}
            {...props}
        >
            <path
                d="M131.597 132.188a73.881 73.881 0 00-29.977-23.87 50.86 50.86 0 0014.636-32.254h4.468c2.383-.037 4.316-2.027 4.282-4.411V58.42a8.887 8.887 0 00-4.389-7.703c-1.361-20.866-15.592-38.15-34.56-43.512A8.63 8.63 0 0077.9 1.063h-8.564a8.627 8.627 0 00-8.152 6.125 46.534 46.534 0 00-20.875 12.506 48.839 48.839 0 00-13.69 31.026 8.884 8.884 0 00-4.389 7.702v13.234c-.033 2.384 1.9 4.374 4.282 4.411h4.467a50.86 50.86 0 0014.636 32.253 73.876 73.876 0 00-29.975 23.868A75.773 75.773 0 00.816 177.54v8.81c-.033 2.384 1.899 4.374 4.282 4.412l137.038.006a4.22 4.22 0 003.028-1.292 4.482 4.482 0 001.254-3.12v-8.816a75.77 75.77 0 00-14.82-45.352zM30.793 67.243v-8.822c2.386-.036 4.32-2.026 4.285-4.412a40.265 40.265 0 018.566-24.92v7.272l-.001.113c0 2.35 1.933 4.284 4.284 4.284 2.35 0 4.284-1.934 4.284-4.284l-.002-.113V21.014a38.021 38.021 0 018.564-4.44v24.198l-.001.112c0 2.35 1.933 4.284 4.284 4.284 2.35 0 4.283-1.934 4.283-4.284l-.001-.112V9.892h8.56v30.88l-.001.13c0 2.35 1.934 4.284 4.284 4.284 2.35 0 4.284-1.934 4.284-4.284l-.002-.13v-24.19c3.032 1.11 5.91 2.602 8.565 4.439v15.34l-.002.13c0 2.35 1.934 4.285 4.284 4.285 2.35 0 4.284-1.935 4.284-4.284l-.002-.13v-7.284a40.271 40.271 0 018.567 24.931c-.034 2.385 1.898 4.374 4.281 4.413v8.822H30.793zm8.782 8.824h68.09c-1.901 19.822-16.438 35.29-34.042 35.29-17.604 0-32.146-15.463-34.048-35.29zm98.28 105.877h-21.413v-26.477l.002-.13c0-2.35-1.934-4.285-4.284-4.285-2.35 0-4.284 1.935-4.284 4.284l.002.131v26.476h-68.52v-26.476l.002-.113c0-2.35-1.934-4.284-4.284-4.284-2.35 0-4.284 1.935-4.284 4.284l.001.113v26.473H9.381v-4.4a66.85 66.85 0 0113.074-40.007 64.884 64.884 0 0131.294-22.911c12.19 7.4 27.547 7.4 39.737 0a64.876 64.876 0 0131.296 22.91 66.85 66.85 0 0113.073 40.008v4.404z"
                fill="#3f4142"
                fillRule="nonzero"
                stroke="#3f4142"
                strokeWidth={5}
            />
        </svg>
    )
}

export default UserSvg
