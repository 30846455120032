import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import HeaderSliderComponent from './components/HeaderSliderComponent';
import ContainerComponent from '../../components/ContainerComponent';
import ServicesTabsComponent from './components/ServicesTabsComponent';
import { FETCH_HOME_DATA_REDUCER } from '../../reducers/HomeReducer';
import { useDispatch, useSelector } from 'react-redux';
import loadable from '@loadable/component'
import LazyLoad from 'react-lazyload';
import { FETCH_CITY_REDUCER } from '../../reducers/CityReducer';
// import FourthSectionComponent from './components/FourthSectionComponent';
// import MeetUsComponent from '../../components/MeetUsComponent';

const ErrorPaperComponent = loadable(() => import('./components/ErrorPaperComponent'))
// const HiddenTitleComponent = loadable(() => import('../../components/HiddenTitleComponent'))
const MainContentComponent = loadable(() => import('./components/MainContentComponent'))

const MainPage = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { services, banner, categories, ramiError } = useSelector(state => state.HomeReducer.data);


  useEffect(() => {
    dispatch(FETCH_CITY_REDUCER())
    if (!banner || !banner.length) {
      dispatch(FETCH_HOME_DATA_REDUCER());
    }
    // eslint-disable-next-line
  }, [])

  return (
    <ContainerComponent
      code='main'
      h1={t('main_page.hidden_title')}
      h1Title={t('main_page.hidden_full_title')}
      disableBack
    >
      <HeaderSliderComponent id='services' data={banner} />
      {!ramiError ?
        <ServicesTabsComponent categories={categories || []} services={services} />
        : <ErrorPaperComponent />}
      <LazyLoad once offset={[200, 0]} placeholder={<div style={{ height: 800, width: '100%' }}></div>}>
        <MainContentComponent />
      </LazyLoad>
    </ContainerComponent>
  )
}


export default React.memo(MainPage);