import React, { useMemo} from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import makeStyles from '@material-ui/core/styles/makeStyles'
import { classNames } from '../../../functions/classNames';

const useStyles = makeStyles((theme) => ({
    label: {
        fontSize: 14,
        textTransform: 'uppercase',
        fontWeight: 550,
        [theme.breakpoints.down('xs')]: {
            fontSize: 12,
        },
        '@media (max-width: 320px)': {
            fontSize: 10,
        },
    },
    checked: {
        borderBottom: `1.5px solid ${theme.palette.secondary.main}`
    },
    radio: {
        padding: 5,
    },
    root: {
        margin: '20px 10px 0',
    }
}))

const RadioLabelComponent = ({ type, label, value }) => {
    const classes = useStyles();
    const checked = useMemo(() => type === value, [type, value])
    return (
        <FormControlLabel
            value={value}
            classes={{ label: classes.label, root: checked ? classNames([classes.root, classes.checked]) : classes.root }}
            control={<Radio color="primary" className={classes.radio}  />}
            label={label}
        />
    )
}


export default React.memo(RadioLabelComponent);