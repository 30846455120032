import React, { useMemo, useCallback } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import InputBase from '@material-ui/core/InputBase'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { FETCH_SEARCH_REDUCER, OPEN_SEARCH_REDUCER } from '../reducers/SearchReducer';
import SearchSvg from '../svg/SearchSvg';
import { useState } from 'react';
import { useEffect } from 'react';
import loadable from '@loadable/component'

const ProdSectSkeletonComponent = loadable(() => import('./skeletons/ProdSectSkeletonComponent'))
const ProductSectionComponent = loadable(() => import('./ProductSectionComponent'))

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        border: 'none',
        borderRadius: 5,
    },
    input: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },

    iconButton: {
        minWidth: 48,
        width: 48,
        padding: '14px 0',
        boxShadow: 'none',
        borderRadius: '0 5px 5px 0',

    },
    icon: {
        height: 20,
    },
    ul: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        position: 'absolute',
        width: '100%',
        height: 450,
        overflowY: 'auto',
        overflowX: 'hidden',
        zIndex: '2',
        border: 'none',
        borderRadius: 0,
        boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 0px 0px rgba(0,0,0,0.12)',
        [theme.breakpoints.down('xs')]: {
            height: 220,
        }
    }
}));

const AutocompleteComponent = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [oldValue, setOldValue] = useState('""');
    const { data, loadingAuto, open } = useSelector(state => state.SearchReducer);
    const state = useSelector(state => state)
    const errorsArray = useMemo(() => Object.keys(state).filter((key) => state[key].errorRami).map((key) => state[key].errorRami), [state]);
    const dispatch = useDispatch();
    const {
        getInputProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
        popupOpen,
    } = useAutocomplete({
        id: 'products-search-autocomplete',
        options: data,
        disableClearable: true,
        clearOnBlur: false,
        openOnFocus: true,
        filterOptions: (options, state) => options,
        getOptionLabel: (option) => option.name,
        onInputChange: (e) => handleChange(e),
    });

    const handleKey = useCallback((e) => {
        if (e.which === 13) {
            e.preventDefault();
        }
    }, [])
    const handleFocus = useCallback((e) => {
        document.getElementById('autocomplete-input').focus();
    }, [])
    const handleChange = useCallback((e) => {
        if (e?.target?.value?.length > 2 && e.target.value !== oldValue) {
            window.stop();
            const value = e.target.value;
            dispatch(FETCH_SEARCH_REDUCER(value))
            setOldValue(value)
        }
    }, [dispatch, oldValue])
    useEffect(() => {
        if (open !== popupOpen) {
            dispatch(OPEN_SEARCH_REDUCER(popupOpen))
        }
        // eslint-disable-next-line
    }, [popupOpen, dispatch])
    useEffect(() => {
        document.querySelector('body').style.overflow = loadingAuto ? true : (popupOpen && groupedOptions.length) ? 'hidden' : 'auto'
    }, [loadingAuto, groupedOptions, popupOpen])

    return (
        <Box position='relative'>
            <Paper component="form" className={classes.root} square>
                <InputBase
                    id="products-search"
                    {...getInputProps()}
                    className={classes.input}
                    onKeyPress={handleKey}
                    placeholder={t('main_page.autocomplete')}
                    inputProps={{ disabled: errorsArray.length, id: 'autocomplete-input', 'aria-label': 'search new products', 'aria-describedby': 'autocomplete-popover' }}
                />
                <Button aria-label="search" className={classes.iconButton} variant="contained" color='primary' onClick={handleFocus}>
                    <SearchSvg className={classes.icon} />
                </Button>
            </Paper>
            {loadingAuto
                ?
                <Paper square className={classes.ul}>
                    <Grid container spacing={2}>
                        <ProdSectSkeletonComponent />
                        <ProdSectSkeletonComponent />
                        <ProdSectSkeletonComponent />
                        <ProdSectSkeletonComponent />
                        <ProdSectSkeletonComponent />
                        <ProdSectSkeletonComponent />
                    </Grid>
                </Paper>
                : groupedOptions.length > 0 ? (
                    <Paper square className={classes.ul}>
                        <Grid container {...getListboxProps()} spacing={2}>
                            {groupedOptions.map((option, index) =>
                                <Grid {...getOptionProps({ option, index })} item md={6} xs={12}>
                                    <ProductSectionComponent
                                        alt={option.name}
                                        src={option.img_url}
                                        name={option.name}
                                        slug={option.slug}
                                        net={option.net_price}
                                        gross={option.gross_price}
                                        currency={option.currency}
                                        uom={option.uom}
                                        title={option.name}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Paper>
                ) : null}
        </Box>
    )
}

export default React.memo(AutocompleteComponent);