import { API_BLOG } from '../constants/api';
import fetchEquipments from '../constants/fetchEquipments';
import { GET } from '../functions/superagentSending';


const initialState = {
    loading: false,
    equipmentLoading: false,
    data: {},
    error: '',
};


const FETCH_POST_REQUEST = 'FETCH_POST_REQUEST'
const FETCH_POST_EQUIPMENTS_REQUEST = 'FETCH_POST_EQUIPMENTS_REQUEST'
const FETCH_POST_EQUIPMENTS_SUCCESS = 'FETCH_POST_EQUIPMENTS_SUCCESS'
const FETCH_POST_SUCCESS = 'FETCH_POST_SUCCESS'
const FETCH_POST_FAILURE = 'FETCH_POST_FAILURE'

const fetchPostRequest = () => ({
    type: FETCH_POST_REQUEST
})

const fetchPostEquipmentsRequest = () => ({
    type: FETCH_POST_EQUIPMENTS_REQUEST
})

const fetchPostSuccess = data => ({
    type: FETCH_POST_SUCCESS,
    payload: data
})
const fetchPostEquipmentsSuccess = data => ({
    type: FETCH_POST_EQUIPMENTS_SUCCESS,
    payload: data
})

const fetchPostFailure = error => ({
    type: FETCH_POST_FAILURE,
    payload: error
})

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_POST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_POST_EQUIPMENTS_REQUEST:
            return {
                ...state,
                equipmentLoading: true
            }
        case FETCH_POST_SUCCESS:
            return {
                loading: false,
                equipmentLoading: false,
                data: payload,
                error: '',
            }
        case FETCH_POST_EQUIPMENTS_SUCCESS:
            return {
                loading: false,
                equipmentLoading: false,
                data: {
                    ...state.data,
                    equipmentsBody: payload,
                },
                error: '',
            }
        case FETCH_POST_FAILURE:
            return {
                ...state,
                loading: false,
                equipmentLoading: false,
                data: {},
                error: payload
            }
        default:
            return state
    }
}

export const FETCH_POST_REDUCER = (slug) => {
    return function (dispatch) {
        dispatch(fetchPostRequest())
        GET(`${API_BLOG}/${slug}`)
            .then(res => {
                dispatch(fetchPostSuccess(res.body.data))
            })
            .catch(error => {
                dispatch(fetchPostFailure(error))
                dispatch(fetchPostFailure(''))
            })
    }
}


export const FETCH_POST_EQUIPMENTS_REDUCER = (ids) => {
    return async (dispatch) => {
        dispatch(fetchPostEquipmentsRequest())
        const equipments = await fetchEquipments(ids);
        dispatch(fetchPostEquipmentsSuccess(equipments))
        // GET_RAMI(`${API_PRODUCTS}?id=[${ids}]`)
        //     .then(res => {
        //         console.log(res.body)
        //         // const body = [
        //         //     res.body[0],
        //         //     // res.body[1],
        //         //     // res.body[2],
        //         //     // res.body[3],
        //         //     // res.body[4],
        //         // ]
        //         dispatch(fetchPostEquipmentsSuccess(res.body))
        //     })
        //     .catch(error => {
        //         // dispatch(fetchPostFailure(error))
        //         // dispatch(fetchPostFailure(''))
        //     })
    }
}
