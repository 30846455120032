import React, { useCallback } from 'react'
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { classNames } from '../functions/classNames';
import RouteLinkComponent from './RouteLinkComponent';
import { ROUTES } from '../constants/routes';

const useStyles = (name) => makeStyles((theme,) => ({
    item: {
        margin: '0 0 0 40px',
        [theme.breakpoints.down('md')]: {
            margin: '0 0 0 20px',
        },
        fontWeight: 550,
        cursor: 'pointer',
        padding: '10px 0',
        position: 'relative',
        display: 'inline-block',
        transition: 'color 0.3s',
        color: theme.palette.primary.main,
        '&::after': {
            content: '""',
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            height: '3px',
            background: theme.palette.secondary.orange,
            opacity: '0',
            transform: 'translateY(5px)',
            transition: 'transform 0.3s, opacity 0.3s'
        },
        '&:hover': {
            color: theme.palette.secondary.orange,
            textShadow: 'none',
            '&::before': {
                opacity: '0',
                transform: 'translateY(-2px)',
            },
            '&::after': {
                opacity: '1',
                transform: 'translateY(0px)',
            },
        },
    },
    activeLink: {
        color: theme.palette.secondary.orange,
        textShadow: 'none',
        '&::before': {
            opacity: '0',
            transform: 'translateY(-2px)',
        },
        '&::after': {
            opacity: '1',
            transform: 'translateY(0px)',
        },
    }
}))

const MenuItemComponent = ({ name, external, pageLink, slug }) => {
    const classes = useStyles(name)();
    const history = useHistory();

    const isActive = useMemo(() => {
        const pathname = history?.location?.pathname;
        return pageLink
            ? pathname === `/${ROUTES['page']}/${slug}`
            : pathname === `/${slug}`

    }, [slug, pageLink, history])

    const handleClick = useCallback(() => {
        document.querySelector('body').style.overflow = 'auto' 
    }, [])
    return (
        <RouteLinkComponent
            to={external ? false : `${pageLink ? ROUTES['page'] : ''}/${slug}`}
            href={external ? slug : false}
        >
            <Typography  onClick={handleClick} className={classNames([classes.item, isActive ? classes.activeLink : ''])} variant='body1' >
                {name}
            </Typography>
        </RouteLinkComponent>
    )
}


export default React.memo(MenuItemComponent);