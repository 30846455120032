import { GET_RAMI } from "../functions/superagentSending";
import { API_PRODUCTS } from "./api";

export default async (ids) => {
    const equipments = [];
    for (let i = 0; i < ids.length; i++) {
        await GET_RAMI(`${API_PRODUCTS}/${ids[i]}`)
                .then(res => {
                    equipments.push(res.body[0])

                })
    }
    return equipments

}