import React, { useCallback } from 'react'
import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import useTheme from "@material-ui/core/styles/useTheme";
import { fade } from '@material-ui/core/styles/colorManipulator';
import { classNames } from '../functions/classNames';
const useStyles = (relative, hiddenBorder) => makeStyles((theme) => ({
    appBar: {
        border: hiddenBorder ? 'none' : `1px solid ${theme.palette.gray.main}`,
        position: relative ? 'relative' : 'none',
        zIndex: 2,
        background: theme.palette.white.main,
        borderTop: 'none',
        borderRadius: 8,
        boxShadow: hiddenBorder ? 'rgba(0, 0, 0, 0.02) 0px 3px 5px -1px, rgba(0, 0, 0, 0.03) 0px 5px 8px 0px, rgba(0, 0, 0, 0.06) 0px 1px 8px 0px' : 'none',
        [theme.breakpoints.down('xs')]: {
            boxShadow: 'none',
            borderRadius: 0,
        },
    },
    tab: {
        color: theme.palette.text.primary,
        fontWeight: 550,
        textTransform: 'none',
        fontSize: 22,
        flex: 0.5,
        padding: '10px 0',
        maxWidth: 'none'
    },
    tabs: {
        color: `${theme.palette.text.primary}30`,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        "& .Mui-selected": {
            color: theme.palette.text.primary,
            background: 'white !important',
        },
        [theme.breakpoints.down('xs')]: {
            borderRadius: 0,
        },
    },
    selected: {
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderTop: `1px solid ${theme.palette.gray.main}`,
    },
    indicator: {
        display: 'none'
    },
    root: {
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        [theme.breakpoints.down('xs')]: {
            borderRadius: 0,
        },
    }
}));

const TabsComponent = ({
    id,
    variant = 'h2',
    hiddenBorder,
    value = 0,
    children,
    titles,
    setValue,
    classWrapper = '',
    relative,
    tabClass = '',
    tabsClass = '',
    appBarClass = '',
}) => {
    const classes = useStyles(relative, hiddenBorder)();
    const theme = useTheme();
    // const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const handleChange = useCallback((event, newValue) => {
        setValue(newValue);
    }, [setValue])
    return (
        <Box mt='20px' mb='20px' className={classNames([classes.appBar, appBarClass])} >
            {/* matches ? "vertical" : 'horizontal' */}
            <Tabs
                id={id}
                value={value}
                orientation={'horizontal'}
                onChange={handleChange}
                // className={classes.tabs}
                classes={{
                    root: classNames([classes.tabs, tabsClass]),
                    indicator: classes.indicator,
                }}
                variant='fullWidth'
            >
                {titles && titles.length
                    ? titles.map((title, index) => <Tab
                        key={index}
                        label={<Typography variant={variant} className={classes.tab} dangerouslySetInnerHTML={{ __html: title }} />}
                        classes={{
                            wrapper: classWrapper,
                            selected: classes.selected,
                            root: classNames([classes.root, tabClass])
                        }}
                        style={{
                            backgroundColor: fade(theme.palette.gray.main, (100 - index * 30) * 0.01)
                        }}
                    />)
                    : ''
                }
            </Tabs>
            {children}
        </Box>
    )
}


export default React.memo(TabsComponent);