import { API_NEWS } from '../constants/api';
import { GET } from '../functions/superagentSending';
import { PER_PAGE } from '../constants';


const initialState = {
    loading: false,
    postLoading: false,
    data: [],
    meta: {},
    error: '',
};


const FETCH_NEWS_REQUEST = 'FETCH_NEWS_REQUEST'
const FETCH_POST_NEWS_REQUEST = 'FETCH_POST_NEWS_REQUEST'
const FETCH_NEWS_SUCCESS = 'FETCH_NEWS_SUCCESS'
const FETCH_NEWS_FAILURE = 'FETCH_NEWS_FAILURE'

const fetchNewsRequest = () => ({
    type: FETCH_NEWS_REQUEST
})

const fetchPostNewsRequest = () => ({
    type: FETCH_POST_NEWS_REQUEST
})

const fetchNewsSuccess = (response, page) => ({
    type: FETCH_NEWS_SUCCESS,
    payload: {
        response, page
    }
})

const fetchNewsFailure = error => ({
    type: FETCH_NEWS_FAILURE,
    payload: error
})

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_NEWS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_POST_NEWS_REQUEST:
            return {
                ...state,
                postLoading: true
            }
        case FETCH_NEWS_SUCCESS:
            return {
                loading: false,
                postLoading: false,
                data: payload.page === 1 ? payload.response?.data : [...state.data, ...payload.response?.data],
                meta: payload?.response?.meta,
                error: '',
            }
        case FETCH_NEWS_FAILURE:
            return {
                loading: false,
                postLoading: false,
                data: {},
                error: payload
            }
        default:
            return state
    }
}

export const FETCH_NEWS_REDUCER = (disableLoader, page) => {
    return function (dispatch) {
        if (disableLoader) {
            dispatch(fetchPostNewsRequest())
        } else {
            dispatch(fetchNewsRequest())
        }
        GET(API_NEWS, { page: page, per_page: PER_PAGE  })
            .then(res => {
                dispatch(fetchNewsSuccess(res.body, page))
            })
            .catch(error => {
                dispatch(fetchNewsFailure(error))
                dispatch(fetchNewsFailure(''))
            })
    }
}
