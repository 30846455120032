import loadable from '@loadable/component'

const EquipmentPage = loadable(() => import('../pages/equipmentPage/EquipmentPage'))
const ServicesPage = loadable(() => import('../pages/servicesPage/ServicesPage'))
const ContactPage = loadable(() => import('../pages/contactPage/ContactPage'))
const AboutPage = loadable(() => import('../pages/aboutPage/AboutPage'))
const BlogPage = loadable(() => import('../pages/blogPage/BlogPage'))
const CareerPage = loadable(() => import('../pages/careerPage/CareerPage'))
const CasesPage = loadable(() => import('../pages/casesPage/CasesPage'))
const NewsPage = loadable(() => import('../pages/newsPage/NewsPage'))
const CustomPage = loadable(() => import('../pages/customPage/CustomPage'))
const RentalRulesPage = loadable(() => import('../pages/rentalRulesPage/RentalRulesPage'))

export default () => {
    EquipmentPage.preload()
    ServicesPage.preload()
    ContactPage.preload()
    AboutPage.preload()
    BlogPage.preload()
    CareerPage.preload()
    CasesPage.preload()
    NewsPage.preload() 
    CustomPage.preload() 
    RentalRulesPage.preload() 
}