import React, { useEffect, useMemo } from "react";
// import HeaderSlideComponent from "./HeaderSlideComponent";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import Box from "@material-ui/core/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PagingComponent from "./PagingComponent";
import DotsComponent from "../../../components/DotsComponent";
import Slider from "react-slick";
// import Skeleton from "@material-ui/lab/Skeleton";
// import LazyLoad from "react-lazyload";
import { useDispatch } from "react-redux";
import { SET_MAIN_REDUCER } from "../../../reducers/MainReducer";
import BannerContentComponent from "../../../components/BannerContentComponent";
import { SMALL_HEIGHT_DEVICES } from "../../../constants";
import slideCss from "../../../constants/slideCss";


const useStyles = (bottomContent) => makeStyles((theme) => ({
  dots: {
    padding: "10px",
    position: "absolute",
    top: bottomContent ? 410 : 345,
    [SMALL_HEIGHT_DEVICES]: {
      top: bottomContent ? 410 : 310,
    },
    [theme.breakpoints.down("sm")]: {
      top: bottomContent ? 410 : 310,
    },
    [theme.breakpoints.down("xs")]: {
      top: 270,
    },
  },
  headerSlider: {
    width: "100%",
    overflow: "hidden",
    background: theme.palette.primary.main,
    ...slideCss(theme, bottomContent),
    "& .slick-active": {
      zIndex: 2,
    },
  },
  li: {
    width: 20,
    height: 20,
    borderRadius: "50%",
    color: theme.palette.white.main,
    margin: "0",
    background: theme.palette.white.main,
  },
  skeleton: {
    width: "100%",
    background: theme.palette.primary.main,
    ...slideCss(theme, bottomContent),
  },
  headerSlide: {
    // backgroundImage: `url(${src})`,
    position: "relative",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top",
    backgroundSize: "cover",
    ...slideCss(theme, bottomContent),
  },
  mainSliderDiv: {
    [theme.breakpoints.down("xs")]: {
      // backgroundImage: `url(${mobileSrc})`,
      height: 300,
    },
  }
}));

const HeaderSliderComponent = ({ id, data, bottomContent }) => {

  const classes = useStyles(bottomContent)();
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'))
  const settings = useMemo(() => ({
    dots: true,
    infinite: true,
    autoplay: true,
    lazyLoad: true,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => (
      <DotsComponent white customClass={classes.dots} dots={dots} />
    ),
    customPaging: (i) => <PagingComponent />,
    beforeChange: (indexOfCurrentSlide, newSlide) => {
      if (data?.length && data[newSlide])
        // data[newSlide].button_color
        dispatch(SET_MAIN_REDUCER('slideDocColor', data[newSlide].button_color))
    }
  }), [data, classes, dispatch])
  useEffect(() => {
    if (data?.length) {
      dispatch(SET_MAIN_REDUCER('slideDocColor', data[0].button_color))
    }
  }, [data, dispatch])
  return (
    <div id={id} className={classes.mainSliderDiv}>
      {/* <LazyLoad
        once
        placeholder={<Skeleton variant="rect" className={classes.skeleton} />}
      > */}
      {data?.length ? (
        <Slider className={classes.headerSlider} {...settings} lazyLoad>
          {data.map((item, index) => (
            <div data-index={index} key={index}>
              <Box className={classes.headerSlide} style={{ backgroundImage: `url(${matches ? item.img_mobile_url : item.img_url})` }}>
                <BannerContentComponent
                  btnColor={item.button_color}
                  content={item.content}
                  text={item.name}
                  href={item.url}
                />
              </Box>
            </div>
          ))}
        </Slider>
      ) : <div className={classes.skeleton}></div>
        // <Skeleton variant="rect" className={classes.skeleton} />
      }
      {/* </LazyLoad> */}
    </div>
  );
};

export default React.memo(HeaderSliderComponent);
