export const VALUTE = 'kr';


// APP INFO
export const APP_RATING = 3.4;
export const APP_REVIEW_AUTHOR = 'Review';
export const APP_REVIEW_BODY = 'Polecam';
export const APP_RATING_COUNT = 9;
export const APP_REVIEW_COUNT = 8;
export const FORMAT_DATE = 'DD.MM.YYYY';


// REGEX

export const CITY_REGEX = /^[a-zA-ZżŻźóÓąĄśćĆńŃęĘŁłŚś]+(?:[\s-][a-zA-ZżŻźóÓąĄśćĆńŃęĘŁłŚś]+)*$/;
export const ZIP_CODE_REGEX = /^\d{1,5}(-\d{1,8})?$/;
// eslint-disable-next-line
export const PHONE_REGEX = /^[\+]?[(]?[0-9]{0,3}[)]?[" "]?[-\s\.]?[0-9]{3}[" "]?[-\s\.]?[0-9]{3}[" "]?[-\s\.]?[0-9]{3,4}$/im;

// URL

export const API_URL = process.env.NODE_ENV === 'production' ? '' : '/cms';
export const RAMI_URL = process.env.NODE_ENV === 'production' ? 'https://ramionline.ramirent.cz' : '/rami';

// MAIN LANGUAGE

export const MAIN_LANGUAGE = 'CS'

// HOW MANY ELEMENTS GET FROM SERVER

export const PER_PAGE = 9;


// REQUEST TYPE

export const RAMI_REQUEST = 0;
export const CMS_REQUEST = 1;


// GOOGLE API KEY

export const MAP_COORDINATES = {
    lat: 49.8037633,
    lng: 15.4749126
}


// EQUIPMENT RADIOS

export const EQUIPMENT_PURPOSE = '0';
export const EQUIPMENT_TYPES = '1';


// COOKIES

export const RAMI_ONLINE_CART_COUNTER_COOKIES = 'RamiOnline_CartCounter'
export const RAMI_ONLINE_USER_NAME_COOKIES = 'RamiOnline_UserName'
export const RAMI_ONLINE_USER_CARTDT_COOKIES = 'RamiOnline_CartDT'
export const RAMI_ONLINE_USER_ID_COOKIES = 'RamiOnline_UserID'
export const RAMI_ONLINE_USER_AVATAR_COOKIES = 'RamiOnline_UserAvatar'
export const RAMI_ONLINE_LANGUAGE_COOKIES = 'language'
export const ALL_FUNC_COOKIES = 'all_func_cookies'
export const FUNCT_COOKIES = [RAMI_ONLINE_CART_COUNTER_COOKIES, RAMI_ONLINE_USER_ID_COOKIES, RAMI_ONLINE_USER_CARTDT_COOKIES, RAMI_ONLINE_USER_NAME_COOKIES, RAMI_ONLINE_USER_AVATAR_COOKIES, RAMI_ONLINE_LANGUAGE_COOKIES];


export const COOKIE_DOMAIN = ".ramirent.cz";

// RAMIRENT OFFICIAL

export const RAMIRENT_URL = 'https://www.ramirent.com'

// RAMI ONLINE URL

export const RAMI_ONLINE_URL = 'https://ramionline.ramirent.cz'

// RAMI ONLINE ROUTES

export const RAMI_ONLINE_CART = '/cart';
export const RAMI_ONLINE_LOGIN = '/my-acc';

// COUNTRIES URLS

export const COUNTRIES_URLS = ['http://ramirent.pl/', 'http://dev.ramirent.sk/',];


// RAMI PLAY STORE

export const RAMI_PLAY_STORE = 'https://play.google.com/store/apps/details?id=cz.ramirent.ramionline';
export const RAMI_APP_STORE = 'https://apps.apple.com/cz/app/ramirent-ramionline/id1523334706';

// AVATAR LINK

export const AVATAR_LINK = '/avatar-img?f=';


// CAREER VIDEO URL

// https://www.youtube.com/embed/xrDGPLcvi-k?modestbranding=1&autoplay=0&controls=0&showinfo=0&rel=0&enablejsapi=1&version=3&playerapiid=iframe_bgndVideo&origin=http%3A%2F%2Frekrutacja.ramirent.pl&allowfullscreen=true&wmode=transparent&iv_load_policy=3&html5=1&widgetid=1
export const CAREER_VIDEO = 'xrDGPLcvi-k';

// KLARAVIK URL

export const KLARAVIK_URL = 'https://www.klaravik.pl/'


// SOCIAL MEDIA

export const FACEBOOK_URL = 'https://www.facebook.com/RAMIRENT-CZ-216642965048562';
// export const TWITTER_URL = 'https://twitter.com/RamirentPoland';
// export const INSTAGRAM_URL = 'https://www.instagram.com/ramirent_polska/';
// export const LINKEDIN_URL = 'https://www.linkedin.com/company/ramirent';
// export const YOUTUBE_URL = 'https://www.youtube.com/channel/UC_DyTQNCa3wFQld1F0hExMw';


// ALL LANGS
// 'CS', 'UK', 'DE', 'EN', 'cs', 'uk', 'de', 'en'
export const ALL_LANGS = ['CS', 'cs'];

// SO FINE LINK

export const SOFINE = 'https://sofine.pl/';

export const SMALL_HEIGHT_DEVICES = '@media (max-height: 810px) and (min-width: 959px)';



export const COOKIES_NAME = 'cookies-shown'
export const COOKIES_REMOVE_NAME = 'remove-cookies-shown'