import { API_BLOG } from '../constants/api';
import { GET } from '../functions/superagentSending';
import { PER_PAGE } from '../constants';

const initialState = {
    loading: false,
    postLoading: false,
    data: {
        posts: [],
    },
    meta: {},
    error: '',
    tags: [],
};


const FETCH_BLOG_REQUEST = 'FETCH_BLOG_REQUEST'
const FETCH_POST_BLOG_REQUEST = 'FETCH_POST_BLOG_REQUEST'
const FETCH_BLOG_SUCCESS = 'FETCH_BLOG_SUCCESS'
const FETCH_BLOG_FAILURE = 'FETCH_BLOG_FAILURE'
const ADD_TAG_BLOG_SUCCESS = 'ADD_TAG_BLOG_SUCCESS'
const DELETE_ALL_TAGS_SUCCESS = 'DELETE_ALL_TAGS_SUCCESS'

const fetchBlogRequest = () => ({
    type: FETCH_BLOG_REQUEST
})
const fetchPostBlogRequest = () => ({
    type: FETCH_POST_BLOG_REQUEST
})
const deleteAllTagsSuccess = () => ({
    type: DELETE_ALL_TAGS_SUCCESS
})

const fetchBlogSuccess = (payload, mobile) => ({
    type: FETCH_BLOG_SUCCESS,
    payload: {
        data: payload, mobile
    },
})

const addTagBlogSuccess = tags => ({
    type: ADD_TAG_BLOG_SUCCESS,
    payload: tags
})
const fetchBlogFailure = error => ({
    type: FETCH_BLOG_FAILURE,
    payload: error
})

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_BLOG_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_POST_BLOG_REQUEST:
            return {
                ...state,
                postLoading: true
            }
        case FETCH_BLOG_SUCCESS:
            return {
                ...state,
                loading: false,
                postLoading: false,
                data: payload.mobile ? {
                    ...state.data,
                    posts: [...state.data.posts, ...payload?.data?.data?.posts]
                    } : payload?.data?.data,
                meta: payload?.data?.meta,
                error: '',
            }
        case FETCH_BLOG_FAILURE:
            return {
                ...state,
                loading: false,
                data: {},
                error: payload
            }
        case ADD_TAG_BLOG_SUCCESS:
            return {
                ...state,
                tags: payload
            }
        case DELETE_ALL_TAGS_SUCCESS:
            return {
                ...state,
                tags: []
            }
        default:
            return state
    }
}

export const DELETE_ALL_TAGS_REDUCER = () => (dispatch) => {
    dispatch(deleteAllTagsSuccess())
}
export const FETCH_BLOG_REDUCER = (disableLoader, mobile, page, tags = '') => {
    return function (dispatch) {
        if (disableLoader){
            dispatch(fetchPostBlogRequest())
        } else {
            dispatch(fetchBlogRequest())
        }
        GET(API_BLOG, { page: page, per_page: PER_PAGE, "filter[tagged]": tags })
            .then(res => {
                dispatch(fetchBlogSuccess(res.body, mobile))
            })
            .catch(error => {
                dispatch(fetchBlogFailure(error))
                dispatch(fetchBlogFailure(''))
            })
    }
}

export const ADD_TAG_BLOG_REDUCER = (tag) => {
    return function (dispatch, getState) {
        dispatch(fetchPostBlogRequest())
        const { BlogReducer } = getState();
        const newTags = [...BlogReducer.tags];
        const index = newTags.findIndex((el) => el === tag);
        if (index >= 0) {
            newTags.splice(index, 1);
        } else {
            newTags.push(tag);
        }
        dispatch(addTagBlogSuccess(newTags))
        dispatch(FETCH_BLOG_REDUCER(true, false, 1, newTags.join(',')))
    }
}
