import React, { useCallback } from 'react'
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import RadioLabelComponent from '../pages/mainPage/components/RadioLabelComponent';
import { EQUIPMENT_TYPES, EQUIPMENT_PURPOSE } from '../constants';
import { useTranslation } from 'react-i18next';


const EqSelectorComponent = ({ setType, type, items, md = 6 }) => {
    const { t } = useTranslation()
    const handleChange = useCallback((e) => {
        setType(e.target.value)
    }, [setType])
    return (
        <Grid item md={md} xs={12}>
            <Box display='flex' justifyContent='center'>
                <FormControl component="fieldset">
                    <RadioGroup row aria-label="type" name="type" onChange={handleChange} value={type}>
                        {items?.length
                            ? items.map((item, index) =>
                                <RadioLabelComponent
                                    type={type}
                                    key={index}
                                    value={`${index}`}
                                    label={item}
                                />)
                            : <>
                                <RadioLabelComponent type={type} value={EQUIPMENT_PURPOSE} label={t('main_page.equip_purpose')} />
                                <RadioLabelComponent type={type} value={EQUIPMENT_TYPES} label={t('main_page.equip_types')} />
                            </>
                        }
                    </RadioGroup>
                </FormControl>
            </Box>
        </Grid>
    )
}


export default React.memo(EqSelectorComponent);