import * as React from "react"

function SearchSvg(props) {
    return (
        <svg viewBox="0 0 35 35" {...props}>
            <path
                fill="#fff"
                d="M34.143 30.007l-7.603-7.603c-.04-.04-.088-.069-.13-.107a14.318 14.318 0 002.37-7.907C28.78 6.442 22.336 0 14.39 0 6.442 0 0 6.442 0 14.39c0 7.947 6.442 14.39 14.39 14.39 2.921 0 5.638-.874 7.907-2.37.038.042.066.09.106.13l7.604 7.603a2.925 2.925 0 004.136-4.136zm-19.758-6.622a9 9 0 110-18 9 9 0 010 18z"
            />
        </svg>
    )
}

export default SearchSvg
