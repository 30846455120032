import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { classNames } from '../functions/classNames';
import { useSelector } from 'react-redux';

const useStyles = (white, slideDocColor) => makeStyles((theme) => ({
    cont: {
    },
    ul: {
        margin: 0,
        padding: 0,
        "&  li": {
            width: 10,
            height: 10,
        },
        "&  div": {
            background: white ? theme.palette.white.main : theme.palette.gray.light,
        },
        "& .slick-active > div": {
            background: slideDocColor || theme.palette.secondary.main
        }
    }
}))

const DotsComponent = ({ white, className, dots, customClass = '', customUlClass = '' }) => {
    const { slideDocColor} = useSelector((state) => state.MainReducer);
    const classes = useStyles(white, slideDocColor)();
    return (
        <div className={classNames([className, customClass])}>
            <ul className={classNames([classes.ul, customUlClass])}> {dots} </ul>
        </div>
    )
}


export default React.memo(DotsComponent);