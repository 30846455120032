import { createMuiTheme } from '@material-ui/core/styles';

export const createTheme = () => {
    const theme = createMuiTheme({
        '*': {
            fontFamily: '"Boing", "Roboto", "Helvetica", "Arial", "sans-serif"',
        },
        typography: {
            fontFamily: '"Boing", "Roboto", "Helvetica", "Arial", "sans-serif"',
        },
        palette: {
            primary: {
                dark: '#305E9A',
                main: '#003287',
                light: '#00000046',
            },
            secondary: {
                dark: '#fff',
                main: '#FFDC00',
                light: '#ffeb3b',
                orange: '#FF963C',
            },
            text: {
                primary: '#3F4142'
            },
            gray: {
                dark: "#3F4142",
                main: "#EDEDED",
                light: '#EFEFEF',
                career: "#2C2C2C",
                background: "#F7F7F7",
                backgroundApp: '#F9F9F9',
                breadcrumbs: "#B9B9B9"

            },
            white: {
                main: '#ffffff'
            },
            red: {
                main: '#E00000'
            },
        },
        overrides: {
            MuiPaper: {
                elevation1: {
                    overflow: 'hidden',
                    boxShadow: 'none',
                    borderRadius: '8px',
                    border: `1.5px solid #EDEDED`
                }
            }
        }
    })
    return {
        ...theme,
    }
}

const mainTheme = createTheme();
export default mainTheme