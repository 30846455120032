import { API_OFFICE_CONTACT, API_CITY, API_OFFICE_PHONE } from '../constants/api';
import { POST, GET_RAMI } from '../functions/superagentSending';


const initialState = {
    loading: false,
    formLoading: false,
    sended: false,
    phoneSended: false,
    activeBranchId: false,
    data: {},
    branch: {},
    error: '',
    formError: '',
};


const FETCH_CITY_REQUEST = 'FETCH_CITY_REQUEST'
const FETCH_CITY_SUCCESS = 'FETCH_CITY_SUCCESS'
const SEND_ACTIVE_BRANCH_ID_SUCCESS = 'SEND_ACTIVE_BRANCH_ID_SUCCESS'
const SEND_CITY_REQUEST = 'SEND_CITY_REQUEST'
const SEND_CITY_SUCCESS = 'SEND_CITY_SUCCESS'
const SEND_PHONE_CITY_SUCCESS = 'SEND_PHONE_CITY_SUCCESS'
const FETCH_CITY_FAILURE = 'FETCH_CITY_FAILURE'
const SEND_CITY_FAILURE = 'SEND_CITY_FAILURE'
const SET_BRANCH_SUCCESS = 'SET_BRANCH_SUCCESS'

const fetchCityRequest = () => ({
    type: FETCH_CITY_REQUEST
})

const fetchCitySuccess = data => ({
    type: FETCH_CITY_SUCCESS,
    payload: data
})
const fetchActiveBranchIdSuccess = data => ({
    type: SEND_ACTIVE_BRANCH_ID_SUCCESS,
    payload: data
})
const setBranchSuccess = data => ({
    type: SET_BRANCH_SUCCESS,
    payload: data
})
const sendCityRequest = () => ({
    type: SEND_CITY_REQUEST
})

const sendCitySuccess = data => ({
    type: SEND_CITY_SUCCESS,
    payload: data
})

const sendPhoneCitySuccess = data => ({
    type: SEND_PHONE_CITY_SUCCESS,
    payload: data
})

const fetchCityFailure = error => ({
    type: FETCH_CITY_FAILURE,
    payload: error
})
const sendCityFailure = error => ({
    type: SEND_CITY_FAILURE,
    payload: error
})

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_CITY_REQUEST:
            return {
                ...state,
                loading: true
            }
        case SET_BRANCH_SUCCESS:
            return {
                ...state,
                branch: payload
            }
        case SEND_CITY_REQUEST:
            return {
                ...state,
                formLoading: true,
                sended: false,
            }
        case SEND_CITY_SUCCESS:
            return {
                ...state,
                formLoading: false,
                sended: payload,
                error: '',
            }
        case SEND_ACTIVE_BRANCH_ID_SUCCESS:
            return {
                ...state,
                activeBranchId: payload,
            }
        case SEND_PHONE_CITY_SUCCESS:
            return {
                ...state,
                formLoading: false,
                phoneSended: payload,
                error: '',
            }
        case FETCH_CITY_SUCCESS:
            return {
                ...state,
                loading: false,
                formLoading: false,
                data: payload?.data,
                branch: payload?.branch,
                error: '',
            }
        case FETCH_CITY_FAILURE:
            return {
                ...state,
                loading: false,
                formLoading: false,
                data: {},
                error: payload
            }
        case SEND_CITY_FAILURE:
            return {
                ...state,
                formLoading: false,
                sended: false,
                formError: payload,
            }
        default:
            return state
    }
}

export const SET_ACTIVE_BRANCH_ID_REDUCER = (id) => (dispatch, getState) => {
    dispatch(fetchActiveBranchIdSuccess(id))
}
export const SET_BRANCH_REDUCER = (value) => (dispatch, getState) => {
    const { ContactReducer } = getState();
    if (ContactReducer?.data?.offices?.length) {
        const newBranch = ContactReducer?.data?.offices.find((item) => item.id === value || item.value === value);
        dispatch(setBranchSuccess(newBranch))
    }
}

export const FETCH_CITY_REDUCER = (slug, branchId) => {
    return function (dispatch) {
        if (slug && branchId) {
            dispatch(fetchCityRequest())
            GET_RAMI(`${API_CITY}/${slug}`)
                .then(res => {
                    const offices = res.body[0].offices?.length
                        ? res.body[0].offices.map((office) => ({
                            ...office,
                            title: office.name,
                            value: office.id,
                            email: office.email,
                            phone: office.contact_number,
                            hours_work: office.hours_work,
                            coords: {
                                lat: parseFloat(office.lat),
                                lng: parseFloat(office.lng),
                            },
                            address: `${office.street}, ${office.zip_code}`,
                            lat: parseFloat(office.lat),
                            lng: parseFloat(office.lng),
                        }))
                        : res.body[0].offices
                    const branch = offices.find((item) => item.id === branchId || item.value === branchId);
                    dispatch(fetchCitySuccess(
                        {
                            branch,
                            data: {
                                ...res.body[0],
                                offices,
                            }
                        }
                    ))
                    window.scrollTo(0, 80);
                })
                .catch(error => {
                    dispatch(fetchCityFailure(error))
                    dispatch(fetchCityFailure(''))
                })
        } else {
            dispatch(fetchCitySuccess({
                branch: {},
                data: {},
            }))
        }
    }
}
export const SEND_CITY_REDUCER = (data) => {
    return function (dispatch) {
        dispatch(sendCityRequest())
        POST(`${API_OFFICE_CONTACT}`, data)
            .then(res => {
                dispatch(sendCitySuccess(true))
                dispatch(sendCitySuccess(false))
            })
            .catch(error => {
                dispatch(sendCityFailure(error))
            })
    }
}
export const SEND_PHONE_CITY_REDUCER = (data) => {
    return function (dispatch) {
        dispatch(sendCityRequest())
        POST(`${API_OFFICE_PHONE}`, data)
            .then(res => {
                dispatch(sendPhoneCitySuccess(true))
                dispatch(sendPhoneCitySuccess(false))
            })
            .catch(error => {
                dispatch(sendCityFailure(error))
            })
    }
}
