import React, { useState, useCallback } from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import useTheme from '@material-ui/core/styles/useTheme'
import Container from '@material-ui/core/Container'
import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/core/styles/makeStyles'
import MenuIcon from '@material-ui/icons/Menu';
import MenuItemComponent from './MenuItemComponent';
import AutocompleteComponent from './AutocompleteComponent';
import { useSelector, useDispatch } from 'react-redux';
import NavigationMenuComponent from './NavigationMenuComponent';
import LogoComponent from './LogoComponent';
import { useEffect } from 'react';
import { classNames } from '../functions/classNames';
import SearchSvg from '../svg/SearchSvg';
import { MOBILE_SWIPE_SHOW_REDUCER } from '../reducers/ContainerReducer';
import loadable from '@loadable/component'
import preloadPages from '../functions/preloadPages'
// import { Typography } from '@material-ui/core'

const IEinfoComponent = loadable(() => import('./IEinfoComponent'))
// import FourthSectionComponent from './components/FourthSectionComponent';
const SideMenuComponent = loadable(() => import('./SideMenuComponent'))
const BottomNavigationComponent = loadable(() => import('./BottomNavigationComponent'))
// import SideMenuComponent from './SideMenuComponent';



const useStyles = makeStyles((theme) => ({
    toolbar: {
        position: 'relative',
        display: 'flex',
        // justifyContent: 'space-between',
        alignItems: 'center',
        background: theme.palette.secondary.main,
        transition: 'padding-top 0.2s ease-in',
        [theme.breakpoints.down('sm')]: {
            paddingTop: 18,
            justifyContent: 'center',
        },
    },
    boxAuto: {
        justifyContent: 'flex-end',
        display: 'flex',
    },
    appBar: {
        zIndex: 100,
        top: 0,
        position: 'sticky',
        boxShadow: 'none',
    },
    menuContainer: {
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    autocompleteCont: {
        marginTop: 10,
        // marginBottom: 30,
        marginBottom: 20,
        flex: 1,
    },
    autoComp: {
        marginTop: 0,
        opacity: 1,
        zIndex: -1,
        transition: 'margin-top 0.3s, opacity 0.15s ease-in',
    },
    autocompHidden: {
        marginTop: -70,
        opacity: 0,
        [theme.breakpoints.down('sm')]: {
            marginTop: -75,
        },
    },
    searchBtn: {
        position: 'absolute',
        right: 0,
        top: 0,
        bottom: 0,
        margin: 5,
        [theme.breakpoints.down('sm')]: {
            marginTop: 5,
        },
    },
    icon: {
        width: 20,
        height: 20,
        '& path': {
            fill: theme.palette.primary.main
        },
    },

    title: {
        fontSize: 16,
        position: 'absolute',
    },
    logoCont: {
        position: 'relative',
    },
    logo: {
        position: 'relative',
        background: theme.palette.secondary.main,
        zIndex: 1,
    }
}))
const AppBarComponent = ({ h1, h1Title }) => {
    const [hiddenEl, setHiddenEl] = useState(false);
    const [showAutoComp, setShowAutoComp] = useState(false);
    const theme = useTheme();
    const openAutocomplete = useSelector(state => state.SearchReducer?.open);
    const dispatch = useDispatch();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();
    const main_menu = useSelector(state => state.ContainerReducer.menu?.main_menu);
    const mobileSwipeShow = useSelector(state => state.ContainerReducer.mobileSwipeShow);
    const handleShowSideMenu = useCallback(() => {
        // document.querySelector('body').style.overflow = !mobileSwipeShow ? 'hidden' : 'auto'
        dispatch(MOBILE_SWIPE_SHOW_REDUCER(!mobileSwipeShow))
    }, [dispatch, mobileSwipeShow])
    const closeSideMenu = useCallback((e) => {
        if (e.key === "Escape") {
            dispatch(MOBILE_SWIPE_SHOW_REDUCER(false))
            document.querySelector('body').style.overflow = 'auto'
        }
    }, [dispatch])
    const handleAutoComp = useCallback(() => {
        setShowAutoComp(!showAutoComp)
    }, [showAutoComp])
    const listenScroll = useCallback((event) => {
        if (window.scrollY > 300 && !openAutocomplete) {
            setHiddenEl(true)
        } else if (window.scrollY < 150 || openAutocomplete) {
            setHiddenEl(false)
        }
    }, [openAutocomplete])

    useEffect(() => {
        window.addEventListener("keydown", closeSideMenu);
        window.addEventListener('scroll', listenScroll)
        return () => {
            window.removeEventListener('scroll', listenScroll)
            window.removeEventListener("keydown", closeSideMenu);
        }
        // eslint-disable-next-line
    }, [])

    const handlePreloadPages = useCallback(() => {
        preloadPages();
    }, [])
    return (
        <>
            <IEinfoComponent />
            <AppBar
                position='static'
                id='appBar'
                color='secondary'
                className={classes.appBar}
            >
                {/* <NavigationMenuComponent hiddenEl={hiddenEl} /> */}
                <Container className={classes.toolbar} style={{ paddingTop: hiddenEl ? 5 : 6 }}>
                    <Box className={classes.logoCont} onMouseOver={handlePreloadPages}>
                        {h1
                            ? <h1 className={classes.title} title={h1Title}>
                                {h1}
                            </h1>
                            : null
                        }
                        <Box className={classes.logo}>
                            <LogoComponent
                                mobileDisable
                                Icon={MenuIcon}
                                onClick={handleShowSideMenu}
                            />
                        </Box>
                    </Box>

                    {matches && hiddenEl
                        ? <IconButton onClick={handleAutoComp} className={classes.searchBtn}>
                            <SearchSvg color='primary' className={classes.icon} />
                        </IconButton>
                        : ""
                    }
                    <Box className={classes.menuContainer}>
                        <Box display='flex' justifyContent='flex-end' alignItems='center' flexWrap='wrap' onMouseOver={handlePreloadPages}>
                            {main_menu?.length
                                ? main_menu.map((item) =>
                                    <MenuItemComponent
                                        key={item.id}
                                        external={item.external_link}
                                        pageLink={item.page_link}
                                        slug={item.slug}
                                        name={item.name}
                                    />
                                )
                                : ''
                            }
                        </Box>
                    </Box>
                    <NavigationMenuComponent
                        hiddenEl={hiddenEl}
                        handleAutoComp={handleAutoComp}
                    />
                </Container>
                <Container className={classNames([classes.autoComp, hiddenEl && !showAutoComp ? classes.autocompHidden : ''])}>
                    <Box className={classes.boxAuto}>
                        <Box className={classes.autocompleteCont}>
                            <AutocompleteComponent />
                        </Box>
                    </Box>
                </Container>
            </AppBar>
            <BottomNavigationComponent handleShowSideMenu={handleShowSideMenu} />
            <SideMenuComponent open={mobileSwipeShow} handleClose={handleShowSideMenu} />
        </>
    )
}


export default React.memo(AppBarComponent);