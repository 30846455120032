import React, { useEffect } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import MainPage from './pages/mainPage/MainPage';
// import AboutPage from './pages/aboutPage/AboutPage';
// import BlogPage from './pages/blogPage/BlogPage';
// import CareerPage from './pages/careerPage/CareerPage';
// import CasePage from './pages/casePage/CasePage';
// import CasesPage from './pages/casesPage/CasesPage';
// import ContactPage from './pages/contactPage/ContactPage';
// import MaterialsPage from './pages/materialsPage/MaterialsPage';
// import NewsPage from './pages/newsPage/NewsPage';
// import PostNewsPage from './pages/postNewsPage/PostNewsPage';
// import OfferPage from './pages/offerPage/OfferPage';
// import PostPage from './pages/postPage/PostPage';
// import EquipmentPage from './pages/equipmentPage/EquipmentPage';
// import CustomPage from './pages/customPage/CustomPage';
// import ClientHelpPage from './pages/clientHelp/ClientHelpPage';
// import ServicesPage from './pages/servicesPage/ServicesPage';
// import EmptyPage from './pages/emptyPage/EmptyPage';
// import MapOfWebsite from './pages/mapOfWebsite/MapOfWebsite';
// import CareerOfferPage from './pages/careerOfferPage/CareerOfferPage';
// import RentalRulesPage from './pages/rentalRulesPage/RentalRulesPage';
// import AppLandingPage from './pages/appLandingPage/AppLandingPage';
// import CityLandingPage from './pages/cityLandingPage/CityLandingPage';
// import RentalPage from './pages/rentalPage/RentalPage';
import loadable from '@loadable/component'
import LoaderComponent from './components/LoaderComponent';
import { ROUTES } from './constants/routes';
import { useCookies } from 'react-cookie';
import { ALL_FUNC_COOKIES, COOKIES_NAME, FUNCT_COOKIES } from './constants';
import AOS from 'aos';
import 'aos/dist/aos.css'
import TagManager from 'react-gtm-module'
import removeFunctCookies from './functions/removeFunctCookies';

const AboutPage = loadable(() => import('./pages/aboutPage/AboutPage'), {
    fallback: <LoaderComponent />,
});
const BlogPage = loadable(() => import('./pages/blogPage/BlogPage'), {
    fallback: <LoaderComponent />,
});
const CareerPage = loadable(() => import('./pages/careerPage/CareerPage'), {
    fallback: <LoaderComponent />,
});
const CasePage = loadable(() => import('./pages/casePage/CasePage'), {
    fallback: <LoaderComponent />,
});
const CasesPage = loadable(() => import('./pages/casesPage/CasesPage'), {
    fallback: <LoaderComponent />,
});
const ContactPage = loadable(() => import('./pages/contactPage/ContactPage'), {
    fallback: <LoaderComponent />,
});
const MaterialsPage = loadable(() => import('./pages/materialsPage/MaterialsPage'), {
    fallback: <LoaderComponent />,
});
const NewsPage = loadable(() => import('./pages/newsPage/NewsPage'), {
    fallback: <LoaderComponent />,
});
const PostNewsPage = loadable(() => import('./pages/postNewsPage/PostNewsPage'), {
    fallback: <LoaderComponent />,
});
const PostPage = loadable(() => import('./pages/postPage/PostPage'), {
    fallback: <LoaderComponent />,
});
const EquipmentPage = loadable(() => import('./pages/equipmentPage/EquipmentPage'), {
    fallback: <LoaderComponent />,
});
const CustomPage = loadable(() => import('./pages/customPage/CustomPage'), {
    fallback: <LoaderComponent />,
});
const ClientHelpPage = loadable(() => import('./pages/clientHelp/ClientHelpPage'), {
    fallback: <LoaderComponent />,
});
const ServicesPage = loadable(() => import('./pages/servicesPage/ServicesPage'), {
    fallback: <LoaderComponent />,
});
const EmptyPage = loadable(() => import('./pages/emptyPage/EmptyPage'), {
    fallback: <LoaderComponent />,
});
const MapOfWebsite = loadable(() => import('./pages/mapOfWebsite/MapOfWebsite'), {
    fallback: <LoaderComponent />,
});
const CareerOfferPage = loadable(() => import('./pages/careerOfferPage/CareerOfferPage'), {
    fallback: <LoaderComponent />,
});
const RentalRulesPage = loadable(() => import('./pages/rentalRulesPage/RentalRulesPage'), {
    fallback: <LoaderComponent />,
});
const AppLandingPage = loadable(() => import('./pages/appLandingPage/AppLandingPage'), {
    fallback: <LoaderComponent />,
});
const CityLandingPage = loadable(() => import('./pages/cityLandingPage/CityLandingPage'), {
    fallback: <LoaderComponent />,
});
const RentalPage = loadable(() => import('./pages/rentalPage/RentalPage'), {
    fallback: <LoaderComponent />,
});
const CustomMaterialsPage = loadable(() => import('./pages/customMaterialsPage/CustomMaterialsPage'), {
    fallback: <LoaderComponent />,
});

const Root = (props) => {
    const [cookies] = useCookies([COOKIES_NAME, ...FUNCT_COOKIES, ALL_FUNC_COOKIES]);
    useEffect(() => {
        AOS.init({
        })
        if (cookies[COOKIES_NAME]) {
            const tagManagerArgs = {
                gtmId: process.env.REACT_APP_GTM
            }
            TagManager.initialize(tagManagerArgs);
        }
        if (cookies[ALL_FUNC_COOKIES]) {
            removeFunctCookies(cookies)
        }
        // eslint-disable-next-line
    }, [cookies])
    return (
        <BrowserRouter>
            <Switch >
                <Route exact path={ROUTES['help']} component={ClientHelpPage} />
                <Route exact path={ROUTES['blog']} component={BlogPage} />
                <Route exact path={ROUTES['career']} component={CareerPage} />
                <Route exact path={`${ROUTES['career']}/:slug`} component={CareerOfferPage} />
                <Route exact path={`${ROUTES['cases']}/:slug`} component={CasePage} />
                <Route exact path={ROUTES['cases']} component={CasesPage} />
                <Route exact path={ROUTES['contact']} component={ContactPage} />
                <Route exact path={`${ROUTES['contact']}/:slug`} component={ContactPage} />
                {/* <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA}>
                </GoogleReCaptchaProvider> */}
                <Route exact path={ROUTES['materials']} component={MaterialsPage} />
                <Route exact path={ROUTES['news']} component={NewsPage} />
                <Route exact path={`${ROUTES['news']}/:slug`} component={PostNewsPage} />
                <Route exact path={`${ROUTES['blog']}/:slug`} component={PostPage} />
                <Route exact path={ROUTES['about']} component={AboutPage} />
                <Route exact path={ROUTES['equipment']} component={EquipmentPage} />
                <Route exact path={`${ROUTES['page']}/:slug`} component={CustomPage} />
                <Route exact path={ROUTES['services']} component={ServicesPage} />
                <Route exact path={ROUTES['empty']} component={EmptyPage} />
                <Route exact path={ROUTES['map']} component={MapOfWebsite} />
                <Route exact path={ROUTES['rental_rules']} component={RentalRulesPage} />
                <Route exact path={`${ROUTES['rental_rules']}/:slug`} component={RentalPage} />
                <Route exact path={ROUTES['app_landing']} component={AppLandingPage} />
                <Route exact path={`${ROUTES['city_landing']}/:slug/:cityId`} component={CityLandingPage} />
                <Route exact path={`${ROUTES['materials_page']}/:slug`} component={CustomMaterialsPage} />
                <Route exact path={ROUTES['main']} component={MainPage} />
                <Route component={EmptyPage} />
            </Switch>
        </BrowserRouter>

    )
}


export default Root;