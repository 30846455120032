import { API_CAREER } from '../constants/api';
import { GET } from '../functions/superagentSending';


const initialState = {
    loading: false,
    dataLoading: false,
    data: {},
    error: '',
};


const FETCH_CAREER_REQUEST = 'FETCH_CAREER_REQUEST'
const FETCH_DATA_CAREER_REQUEST = 'FETCH_DATA_CAREER_REQUEST'
const FETCH_CAREER_SUCCESS = 'FETCH_CAREER_SUCCESS'
const FETCH_JUST_CAREER_SUCCESS = 'FETCH_JUST_CAREER_SUCCESS'
const FETCH_CAREER_FAILURE = 'FETCH_CAREER_FAILURE'

const fetchCareerRequest = () => ({
    type: FETCH_CAREER_REQUEST
})
const fetchDataCareerRequest = () => ({
    type: FETCH_DATA_CAREER_REQUEST
})

const fetchCareerSuccess = data => ({
    type: FETCH_CAREER_SUCCESS,
    payload: data
})

const fetchJustCareerSuccess = data => ({
    type: FETCH_JUST_CAREER_SUCCESS,
    payload: data
})

const fetchCareerFailure = error => ({
    type: FETCH_CAREER_FAILURE,
    payload: error
})

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_CAREER_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_DATA_CAREER_REQUEST:
            return {
                ...state,
                dataLoading: true
            }
        case FETCH_CAREER_SUCCESS:
            return {
                loading: false,
                dataLoading: false,
                data: payload,
                error: '',
            }
        case FETCH_JUST_CAREER_SUCCESS:
            return {
                loading: false,
                dataLoading: false,
                data: {
                    ...state.data,
                    careers: payload
                },
                error: '',
            }
        case FETCH_CAREER_FAILURE:
            return {
                loading: false,
                dataLoading: false,
                data: {},
                error: payload
            }
        default:
            return state
    }
}

export const FETCH_CAREER_REDUCER = (disableLoader, province) => {
    return function (dispatch) {
        if (disableLoader) {
            dispatch(fetchCareerRequest())
        } else {
            dispatch(fetchDataCareerRequest())
        }
        GET(API_CAREER, { "filter[region]": province || '' })
            .then(res => {
                dispatch(province
                    ? fetchJustCareerSuccess(res.body.data.careers)
                    : fetchCareerSuccess(res.body.data)
                )
            })
            .catch(error => {
                dispatch(fetchCareerFailure(error))
                dispatch(fetchCareerFailure(''))
            })
    }
}
