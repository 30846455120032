export const ROUTES = {
	"main": "/",
	"about": "/o-firme",
	"equipment": "/technika",
	"services": "/sluzby",
	"blog": "/rami-pomocnik",
	"career": "/kariera",
	"cases": "/realizace",
	"contact": "/kontakt",
	"help": "/zakaznicky-podpora",
	"materials": "/materialy-ke-stazeni",
	"news": "/novinky",
	"policy": "/stranka/osobni-udaje",
	"materials_page": "/strona-materialy",
	"page": "/stranka",
	"rental_rules": "/podminky-pronajmu",
	"map": "/mapa-stranky",
	"app_landing": "/rami-online-aplikace",
	"city_landing": "/mesto",
	"empty": "/error"
}

export const COOKIES_ROUTE = "stranka/cookies";