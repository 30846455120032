import React from 'react'
import {Link} from 'react-router-dom';
import { classNames } from '../functions/classNames';
import routeGenerate from '../functions/routeGenerate';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    link: {
        textDecoration: 'none',
        cursor: 'pointer',
        color: 'inherit',
        '&:visited': {
            color: "inherit"
        }
    }
}))
const RouteLinkComponent = ({ blank, children, to, href, customClass = '', linkCustomClass = '', style, rel }) => {
    const classes = useStyles();
    return (
        <>
            {to ? <Link className={classNames([classes.link, customClass])} to={routeGenerate(to)}>
                {children}
            </Link> : ''}
            {href ? <a href={href} className={classNames([classes.link, linkCustomClass])} style={style} target={blank ? '_blank' : '_self'} rel={blank ? "noopener noreferrer" : undefined }>
                {children}
            </a > : ''}
        </>
    )
}


export default React.memo(RouteLinkComponent);