import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton';
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 10,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        background: 'white',
        '&:hover': {
            overflow: 'hidden',
            background: theme.palette.secondary.main,
            borderColor: theme.palette.secondary.main,
            '& path': {
                fill: theme.palette.gray.dark,
            },
            '& img': {
                filter: 'grayscale(1)',
            }
        },
        transition: 'all 0.2s ease-in',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            maxWidth: 'none',
        },
        '@media (max-width: 400px)': {
            padding: 5,
        },
    },
    mainCont: {
        margin: '10px 5px',
        position: 'relative',
        height: 'calc(100% - 10px)',
        [theme.breakpoints.down('xs')]: {
            width: 'calc(100% - 5px)',
            margin: '5px 2.5px',
            height: 'inherit',
        },
        '&::after': {
            content: "''",
            display: 'block',
            paddingBottom: '100%',
        },
        
    },
    grid: {
        height: 'inherit',
        maxWidth: 150,
        flexBasis: 140,
        [theme.breakpoints.down('xs')]: {
            maxWidth: '33.33%',
            flexBasis: '33.33%',
        },
        '@media (max-width: 370px)': {
            flexBasis: '50%',
            maxWidth: '50%',
        },
    },
    linkCont: {
        position: 'absolute',
        width: '100%',
        height: '100%',
    },
    margin: {
        margin: 'auto',
    }
}))

const ServiceSkeletonComponent = () => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Grid item md={3} xs={4} className={classes.grid}>
            <Box className={classes.mainCont}>
                <Box className={classes.linkCont}>
                    <Paper className={classes.container}>
                        <Skeleton variant="circle" className={classes.margin} width={matches ? 40 : 70} height={matches ? 40 : 70} />
                        <Skeleton variant="text" className={classes.margin} width='50%' />
                        <Skeleton variant="text" className={classes.margin} width='50%' />
                        <Skeleton variant="text" className={classes.margin} width='50%' />
                    </Paper>
                </Box>
            </Box>
        </Grid >
    )
}


export default React.memo(ServiceSkeletonComponent);