import { API_EQUIPMENT } from '../constants/api';
import {  GET_RAMI } from '../functions/superagentSending';


const initialState = {
    loading: false,
    data: {},
    ramiError: '',
};


const FETCH_EQUIPMENT_REQUEST = 'FETCH_EQUIPMENT_REQUEST'
const FETCH_EQUIPMENT_SUCCESS = 'FETCH_EQUIPMENT_SUCCESS'
const FETCH_EQUIPMENT_FAILURE = 'FETCH_EQUIPMENT_FAILURE'

const fetchEquipmentRequest = () => ({
    type: FETCH_EQUIPMENT_REQUEST
})

const fetchEquipmentSuccess = data => ({
    type: FETCH_EQUIPMENT_SUCCESS,
    payload: data
})

const fetchEquipmentFailure = error => ({
    type: FETCH_EQUIPMENT_FAILURE,
    payload: error
})

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_EQUIPMENT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_EQUIPMENT_SUCCESS:
            return {
                loading: false,
                data: payload,
                ramiError: '',
            }
        case FETCH_EQUIPMENT_FAILURE:
            return {
                ...state,
                loading: false,
                data: {},
                ramiError: payload
            }
        default:
            return state
    }
}

export const FETCH_EQUIPMENT_REDUCER = () => {
    return function (dispatch) {
        dispatch(fetchEquipmentRequest())
        GET_RAMI(API_EQUIPMENT)
            .then(res => {
                dispatch(fetchEquipmentSuccess(res.body))
            })
            .catch(error => {
                dispatch(fetchEquipmentFailure(error))
            })
    }
}
