import { API_RULES } from '../constants/api';
import { GET } from '../functions/superagentSending';

const initialState = {
    loading: false,
    postLoading: false,
    data: [],
    meta: {},
    error: '',
};


const FETCH_RULES_REQUEST = 'FETCH_RULES_REQUEST'
const FETCH_POST_RULES_REQUEST = 'FETCH_POST_RULES_REQUEST'
const FETCH_RULES_SUCCESS = 'FETCH_RULES_SUCCESS'
const FETCH_RULES_FAILURE = 'FETCH_RULES_FAILURE'

const fetchRulesRequest = () => ({
    type: FETCH_RULES_REQUEST
})

const fetchPageRulesRequest = () => ({
    type: FETCH_POST_RULES_REQUEST
})

const fetchRulesSuccess = (response, page) => ({
    type: FETCH_RULES_SUCCESS,
    payload: {
        response, page
    }
})

const fetchRulesFailure = error => ({
    type: FETCH_RULES_FAILURE,
    payload: error
})

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_RULES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_POST_RULES_REQUEST:
            return {
                ...state,
                postLoading: true
            }
        case FETCH_RULES_SUCCESS:
            return {
                loading: false,
                postLoading: false,
                data: payload.response?.data,
                error: '',
            }
        case FETCH_RULES_FAILURE:
            return {
                loading: false,
                postLoading: false,
                data: {},
                error: payload
            }
        default:
            return state
    }
}

export const FETCH_RULES_REDUCER = (disableLoader) => {
    return function (dispatch) {
        if (disableLoader) {
            dispatch(fetchPageRulesRequest())
        } else {
            dispatch(fetchRulesRequest())
        }
        GET(API_RULES)
            .then(res => {
                dispatch(fetchRulesSuccess(res.body))
            })
            .catch(error => {
                dispatch(fetchRulesFailure(error))
                dispatch(fetchRulesFailure(''))
            })
    }
}
