import superagent from 'superagent';
// RAMI_ONLINE_LANGUAGE_COOKIES
import { API_URL, MAIN_LANGUAGE, RAMI_URL, ALL_LANGS } from '../constants';
// import { getCookie } from './cookie';

// const baseUrl = `${process.env.NODE_ENV === 'production' ? API_URL : ''}`
const urlParams = new URLSearchParams(window.location.search);
const lngParam = urlParams.get('lang');
const actualLang = ALL_LANGS.find((l) => lngParam === l);
// const lng = getCookie(RAMI_ONLINE_LANGUAGE_COOKIES);
// const lang = actualLang || lng || MAIN_LANGUAGE;
const lang = actualLang || MAIN_LANGUAGE;
const set = {
    'Access-Control-Allow-Origin': "*",
    "Access-Control-Allow-Methods": "GET,POST,OPTIONS,DELETE,PUT",
    "Accept": 'application/json',
    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization, 'application/json'",
}
export const POST = (url, data = {}) => {
    return superagent
        .post(`${API_URL}/${lang.toLowerCase()}/api${url}`)
        .send(data)
        .set(set)
}

export const GET = (url, query = {}) => {
    return superagent
        .get(`${API_URL}/${lang.toLowerCase()}/api${url}`)
        .query(query)
        .set(set)
}
export const GET_RAMI = (url, query = {}) => superagent
    .get(`${RAMI_URL}/api/${lang.toLowerCase()}${url}`)
    .auth(process.env.REACT_APP_RAMI_AUTH_USER, process.env.REACT_APP_RAMI_AUTH_PASSWORD)
    .query(query)
    .set(set)
