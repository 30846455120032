import React from 'react'
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles'
import NavSearchSvg from '../svg/NavSearchSvg';
import NavigationItemComponent from './NavigationItemComponent';
import { RAMI_ONLINE_USER_NAME_COOKIES, RAMI_ONLINE_USER_AVATAR_COOKIES, RAMI_ONLINE_LOGIN, RAMI_ONLINE_CART, AVATAR_LINK, RAMI_ONLINE_URL } from '../constants';
import { useTranslation } from 'react-i18next';
import UserSvg from '../svg/UserSvg';
import CartComponent from './CartComponent';
import { useCookies } from 'react-cookie';
import { useMemo } from 'react';
// import LangBoxComponent from './LangBoxComponent';
import RouteLinkComponent from './RouteLinkComponent';
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    subNavigation: {
        paddingTop: 10, // remove after lang integration
        paddingBottom: 10,  // remove after lang integration
        transition: 'margin-top 0.3s ease-in',
        color: theme.palette.primary.main,
        flex: 1,
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    },
    cart: {
        margin: '0',
    },
    lang: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    deskSearchBtn: {
        minWidth: 'auto',
        marginLeft: 15,
    },
    icon: {
        width: 20,
        height: 20,
        '& path': {
            fill: theme.palette.primary.main
        }
    },
}));

const NavigationMenuComponent = ({ hiddenEl, handleAutoComp = () => { } }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [cookies] = useCookies();
    const userAvatar = useMemo(() => cookies[RAMI_ONLINE_USER_AVATAR_COOKIES], [cookies])
    const label = useMemo(() => cookies[RAMI_ONLINE_USER_NAME_COOKIES], [cookies]);


    return (
        <Box className={classes.subNavigation} >
            {hiddenEl
                ? <IconButton onClick={handleAutoComp} className={classes.deskSearchBtn} size='small'>
                    <NavSearchSvg color='primary' className={classes.icon} />
                </IconButton>
                : ''
            }
            <NavigationItemComponent
                hiddenEl={hiddenEl}
                isAvatar={userAvatar}
                Icon={userAvatar ? Avatar : UserSvg}
                src={`${RAMI_ONLINE_URL}${AVATAR_LINK}${userAvatar}`}
                alt={label || t('menu.login')}
                href={`${RAMI_ONLINE_URL}${RAMI_ONLINE_LOGIN}`}
            >
                <RouteLinkComponent href={`${RAMI_ONLINE_URL}${RAMI_ONLINE_CART}`}>
                    <IconButton aria-label="cart" size='small'>
                        <CartComponent className={classes.cart} />
                    </IconButton>
                </RouteLinkComponent>
                {/* <LangBoxComponent color='secondary' size='small' classLang={classes.lang} deskVer /> */}
            </NavigationItemComponent>
        </Box>
    )
}


export default React.memo(NavigationMenuComponent);