import React from 'react'
import Badge from '@material-ui/core/Badge';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ShopCart from '../svg/ShopCart';
import { classNames } from '../functions/classNames';
import { useCookies } from 'react-cookie';
import { RAMI_ONLINE_CART_COUNTER_COOKIES } from '../constants';

const useStyles = makeStyles((theme) => ({
    badge: {
        top: 8,
        fontWeight: 550,
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.white.main,
        backgroundColor: theme.palette.red.main
    },
    shoppingCartIcon: {
        width: 25,
        height: 25,
        '& .ShopCart': {
            fill: theme.palette.primary.main,
            [theme.breakpoints.down('sm')]: {
                fill: theme.palette.white.main,
            },
        }
    },
}))
const CartComponent = ({ className = '' }) => {
    const classes = useStyles();
    const [cartNumb] = useCookies();
    return (
        <Badge badgeContent={cartNumb[RAMI_ONLINE_CART_COUNTER_COOKIES] || '0'} classes={{
            colorSecondary: classes.badge
        }} color='secondary'>
            <ShopCart className={classNames([classes.shoppingCartIcon, className])} />
        </Badge>
    )
}


export default React.memo(CartComponent);