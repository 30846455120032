import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { classNames } from '../../../functions/classNames';

const useStyles = makeStyles((theme) => ({
    li: {
        width: 10,
        height: 10,
        borderRadius: '50%',
        color: theme.palette.white.main,
        margin: '0',
        background: theme.palette.white.main,
    }
}));

const PagingComponent = (props) => {
    const { className = '' } = props
    const classes = useStyles();
    return (
        <div {...props} className={classNames([className, classes.li])}>
        </div>
    )
}


export default React.memo(PagingComponent);