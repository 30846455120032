import { API_NEWS } from '../constants/api';
import { GET } from '../functions/superagentSending';

const initialState = {
    loading: false,
    data: {
        posts: [],
    },
    error: '',
};


const FETCH_NEWS_POST_REQUEST = 'FETCH_NEWS_POST_REQUEST'
const FETCH_NEWS_POST_SUCCESS = 'FETCH_NEWS_POST_SUCCESS'
const FETCH_NEWS_POST_FAILURE = 'FETCH_NEWS_POST_FAILURE'

const fetchNewsPostRequest = () => ({
    type: FETCH_NEWS_POST_REQUEST
})

const fetchNewsPostSuccess = data => ({
    type: FETCH_NEWS_POST_SUCCESS,
    payload: data
})

const fetchNewsPostFailure = error => ({
    type: FETCH_NEWS_POST_FAILURE,
    payload: error
})

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_NEWS_POST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_NEWS_POST_SUCCESS:
            return {
                loading: false,
                data: payload,
                error: '',
            }
        case FETCH_NEWS_POST_FAILURE:
            return {
                ...state,
                loading: false,
                data: {},
                error: payload
            }
        default:
            return state
    }
}

export const FETCH_NEWS_POST_REDUCER = (slug) => {
    return function (dispatch) {
        dispatch(fetchNewsPostRequest())
        GET(`${API_NEWS}/${slug}`)
            .then(res => {
                dispatch(fetchNewsPostSuccess(res.body.data))
            })
            .catch(error => {
                dispatch(fetchNewsPostFailure(error))
                dispatch(fetchNewsPostFailure(''))
            })
    }
}
