import * as React from "react"

function ShopCart(props) {
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" {...props}>
            <defs>
                <style>{".ShopCart{fill:#ffd900}"}</style>
            </defs>
            <path fill="none" d="M0 0h32v32H0z" />
            <path
                className="ShopCart"
                d="M31.503 7.751a.886.886 0 00-.878-.878H7.503q-.453-1.561-.9-3.119c-.07-.239-.137-.477-.207-.713a.889.889 0 00-.85-.646h-4.35a.878.878 0 100 1.757h3.692q.487 1.671.971 3.344l1.585 5.467a.9.9 0 00.1.344c.22.751.436 1.505.656 2.256q.912 3.15 1.827 6.3c.07.239.137.477.207.713a.889.889 0 00.846.646h16.528a.878.878 0 000-1.757H11.736l-.718-2.47h16.1a.886.886 0 00.878-.878.845.845 0 00-.15-.484q.845-2.492 1.687-4.986.687-2.038 1.378-4.076a.878.878 0 00.592-.82zm-2.453.878q-.573 1.695-1.149 3.392h-3.644l.43-3.392h4.363zm-5.454 8.614l.439-3.469h3.274q-.587 1.733-1.171 3.469zm-4.165 0v-3.469h2.847l-.01.067q-.215 1.7-.433 3.4h-2.406zm-4.162 0l-.439-3.469h2.848v3.469zm-5.763-3.465h3.567l.01.067q.215 1.7.433 3.4h-3q-.508-1.735-1.011-3.467zm9.925-5.149h3.5l-.43 3.392H19.43V8.629zm-1.753 3.392h-3.069l-.43-3.392h3.5c-.002 1.13-.002 2.266-.002 3.392zM10.47 8.629h1.948l.43 3.392H8.993q-.286-.983-.57-1.967l-.414-1.426zM16.423 26.601a2.358 2.358 0 100 .2.536.536 0 000-.1v-.1zM26.956 26.601a2.358 2.358 0 100 .2.538.538 0 000-.1v-.1z"
            />
        </svg>
    )
}

export default ShopCart
