import React, { useState } from 'react'
import TabsComponent from '../../../components/TabsComponent';
import { useTranslation } from 'react-i18next';
import ServiceTabComponent from './ServiceTabComponent';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Container from "@material-ui/core/Container";
import { SMALL_HEIGHT_DEVICES } from '../../../constants';

const useStyles = makeStyles((theme) => ({
    tabs: {
        marginTop: -340,
        [SMALL_HEIGHT_DEVICES]: {
            marginTop: -320,
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: -320,
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: 20,
            marginTop: -20,
            borderRadius: 0,
            background: 'rgb(237, 237, 237)',
        },
        "& .MuiTab-root": {
            backgroundColor: `${theme.palette.white.main}90 !important`,

        },
        "& .Mui-selected": {
            color: theme.palette.text.primary,
            backgroundColor: `initial !important`,
        },
    },
    cont: {
        marginBottom: 75,
        [SMALL_HEIGHT_DEVICES]: {
            marginBottom: 55,
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: 55,
            paddingLeft: 0,
            paddingRight: 0,
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: 0,
        },
    },
    appBar: {
        background: `${theme.palette.white.main}90 !important`
    }
}))

const ServicesTabsComponent = ({ categories, services }) => {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const { t } = useTranslation();
    return (
        <Container className={classes.cont}>
            <TabsComponent
                value={value}
                variant='body1'
                relative
                hiddenBorder
                tabClass={classes.tab}
                tabsClass={classes.tabs}
                appBarClass={classes.appBar}
                setValue={setValue}
                titles={[t('main_page.equipment_title'), t('main_page.services_title')]}
            >
                <ServiceTabComponent value={value} startTabIndex={'1'} index={0} items={categories} />
                <ServiceTabComponent value={value} index={1} items={services} isService />
            </TabsComponent>
        </Container>
    )
}


export default React.memo(ServicesTabsComponent);