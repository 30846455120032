import { API_CASES } from '../constants/api';
import { GET } from '../functions/superagentSending';
import { PER_PAGE } from '../constants';


const initialState = {
    loading: false,
    postLoading: false,
    data: {
        cases: [],
    },
    meta: {},
    error: '',
    categories: [],
};


const FETCH_CASES_REQUEST = 'FETCH_CASES_REQUEST'
const FETCH_POST_CASES_REQUEST = 'FETCH_POST_CASES_REQUEST'
const FETCH_CASES_SUCCESS = 'FETCH_CASES_SUCCESS'
const ADD_CATEGORIES_CASES_SUCCESS = 'ADD_CATEGORIES_CASES_SUCCESS'
const FETCH_CASES_FAILURE = 'FETCH_CASES_FAILURE'

const fetchCasesRequest = () => ({
    type: FETCH_CASES_REQUEST
})
const fetchPostCasesRequest = () => ({
    type: FETCH_POST_CASES_REQUEST
})

const fetchCasesSuccess = (response, page) => ({
    type: FETCH_CASES_SUCCESS,
    payload: {
        response, page
    }
})

const fetchCasesFailure = error => ({
    type: FETCH_CASES_FAILURE,
    payload: error
})
const addCategoriesCasesSuccess = categories => ({
    type: ADD_CATEGORIES_CASES_SUCCESS,
    payload: categories
})

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_CASES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_POST_CASES_REQUEST:
            return {
                ...state,
                postLoading: true
            }
        case FETCH_CASES_SUCCESS:
            return {
                ...state,
                loading: false,
                postLoading: false,
                data: payload.page === 1
                    ? payload.response?.data
                    : {
                        case_category: state.data.case_category,
                        cases: [...state.data.cases, ...payload.response?.data.cases],
                    },
                meta: payload?.response?.meta,
                error: '',
            }
        case FETCH_CASES_FAILURE:
            return {
                ...state,
                loading: false,
                postLoading: false,
                data: {},
                error: payload
            }
        case ADD_CATEGORIES_CASES_SUCCESS:
            return {
                ...state,
                categories: payload.length !== state.data?.case_category?.length ? payload : [] 
            }
        default:
            return state
    }
}

export const FETCH_CASES_REDUCER = (disableLoader, page, categories = '') => {
    return function (dispatch) {
        if (disableLoader) {
            dispatch(fetchPostCasesRequest())
        } else {
            dispatch(fetchCasesRequest())
        }
        GET(API_CASES, { page: page, per_page: PER_PAGE, "filter[category]": categories })
            .then(res => {
                dispatch(fetchCasesSuccess(res.body, page))
            })
            .catch(error => {
                dispatch(fetchCasesFailure(error))
                dispatch(fetchCasesFailure(''))
            })
    }
}

export const ADD_CATEGORIES_CASES_REDUCER = (category) => {
    return function (dispatch, getState) {
        dispatch(fetchPostCasesRequest())
        const {CasesReducer} = getState(); 
        const newCategories = [...CasesReducer.categories];
        const index = newCategories.findIndex((el) => el === category); 
        if (index >= 0){
            newCategories.splice(index,1);
        } else{
            newCategories.push(category); 
        }
        dispatch(addCategoriesCasesSuccess(newCategories)) 
        dispatch(FETCH_CASES_REDUCER(true,1, newCategories.join(','))) 
    }
}

