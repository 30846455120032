import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import cs from './cs.json'
// import en from './en.json'
// import uk from './uk.json'
// import de from './de.json'
// RAMI_ONLINE_LANGUAGE_COOKIES
import { MAIN_LANGUAGE, ALL_LANGS } from '../constants';
// import { getCookie, setCookie } from '../functions/cookie';

const resources = {
    CS: {
        translation: cs
    },
    // EN: {
    //     translation: en
    // },
    // UK: {
    //     translation: uk
    // },
    // DE: {
    //     translation: de
    // },
    cs: {
        translation: cs
    },
    // en: {
    //     translation: en
    // },
    // uk: {
    //     translation: uk
    // },
    // de: {
    //     translation: de
    // },
};

// LANGUAGE 
// const urlParams = new URLSearchParams(window.location.search);
// const lngParam = urlParams.get('lang');
// const actualLang = ALL_LANGS.find((l) => lngParam === l);
// if (!getCookie(RAMI_ONLINE_LANGUAGE_COOKIES) && lngParam) {
//     setCookie(RAMI_ONLINE_LANGUAGE_COOKIES, actualLang || MAIN_LANGUAGE, 365);
// }

i18n

    .use(initReactI18next)
    .init({
        resources,
        // lng: actualLang || getCookie(RAMI_ONLINE_LANGUAGE_COOKIES) || MAIN_LANGUAGE,
        lng: MAIN_LANGUAGE,
        fallbackLng: ALL_LANGS,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });



export default i18n;