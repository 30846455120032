const { createProxyMiddleware } = require("http-proxy-middleware")

module.exports = app => {
  app.use('/cms',
    createProxyMiddleware({
      target: 'https://dev.ramirent.cz',
      // ws: true,
      pathRewrite: {
        '^/cms': '',
      },
      changeOrigin: true,
      // logLevel: 'debug'
    }));
  app.use('/rami',
    createProxyMiddleware({
      target: 'https://ramionline.ramirent.cz',
      // ws: true,
      pathRewrite: {
        '^/rami': '',
      },
      changeOrigin: true,
      // logLevel: 'debug'
    }));
}