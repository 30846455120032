import { SMALL_HEIGHT_DEVICES } from ".";

export default (theme, bottomContent) => ({
	height: 700,
	[SMALL_HEIGHT_DEVICES]: {
		height: bottomContent ? 700 : 650,
	},
	[theme.breakpoints.down("sm")]: {
		height: bottomContent ? 700 : 650,
	},
	[theme.breakpoints.down("xs")]: {
		height: 300,
	},
})