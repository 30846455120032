import React from 'react'
import ServiceComponent from './ServiceComponent';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    grid: {
        height: 'inherit',
        maxWidth: 150,
        flexBasis: 140,
        [theme.breakpoints.down('xs')]: {
            flexBasis: '33.33%',
            maxWidth: '33.33%',
        },
        '@media (max-width: 370px)': {
            flexBasis: '50%',
            maxWidth: '50%',
        },
    }
}))

const ServiceTabContentComponent = ({ lazy, to, data, types, titleVariant }) => {
    const classes = useStyles();

    return (
        <>
            <Grid container justify='center'>
                {
                    data.map((item) =>
                        <Grid key={item.id} item xs={4} className={classes.grid}>
                            <ServiceComponent
                                to={to}
                                lazy={lazy}
                                titleVariant={titleVariant}
                                src={item.img_url}
                                activeSrc={item.imgactive_url}
                                Icon={item.Icon}
                                title={item.name}
                                slug={item.slug}
                            />
                        </Grid>
                    )
                }
            </Grid>


        </>
    )
}


export default React.memo(ServiceTabContentComponent);