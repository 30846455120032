import { API_CASES } from '../constants/api';
import fetchEquipments from '../constants/fetchEquipments';
import { GET } from '../functions/superagentSending';

const initialState = {
    loading: false,
    equipmentLoading: false,
    data: {},
    error: '',
};


const FETCH_CASE_ITEM_REQUEST = 'FETCH_CASE_ITEM_REQUEST'
const FETCH_CASE_ITEM_EQUIPMENTS_REQUEST = 'FETCH_CASE_ITEM_EQUIPMENTS_REQUEST'
const FETCH_CASE_ITEM_EQUIPMENTS_SUCCESS = 'FETCH_CASE_ITEM_EQUIPMENTS_SUCCESS'
const FETCH_CASE_ITEM_SUCCESS = 'FETCH_CASE_ITEM_SUCCESS'
const FETCH_CASE_ITEM_FAILURE = 'FETCH_CASE_ITEM_FAILURE'

const fetchCaseItemRequest = () => ({
    type: FETCH_CASE_ITEM_REQUEST
})

const fetchCaseItemEquipmentsRequest = () => ({
    type: FETCH_CASE_ITEM_EQUIPMENTS_REQUEST
})


const fetchCaseItemSuccess = data => ({
    type: FETCH_CASE_ITEM_SUCCESS,
    payload: data
})

const fetchCaseItemEquipmentsSuccess = data => ({
    type: FETCH_CASE_ITEM_EQUIPMENTS_SUCCESS,
    payload: data
})


const fetchCaseItemFailure = error => ({
    type: FETCH_CASE_ITEM_FAILURE,
    payload: error
})

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_CASE_ITEM_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_CASE_ITEM_EQUIPMENTS_REQUEST:
            return {
                ...state,
                equipmentLoading: true
            }
        case FETCH_CASE_ITEM_SUCCESS:
            return {
                loading: false,
                equipmentLoading: false,
                data: payload,
                error: '',
            }
        case FETCH_CASE_ITEM_EQUIPMENTS_SUCCESS:
            return {
                loading: false,
                equipmentLoading: false,
                data: {
                    ...state.data,
                    equipmentsBody: payload,
                },
                error: '',
            }
        case FETCH_CASE_ITEM_FAILURE:
            return {
                loading: false,
                equipmentLoading: false,
                data: {},
                error: payload
            }
        default:
            return state
    }
}

export const FETCH_CASE_ITEM_REDUCER = (slug) => {
    return function (dispatch) {
        dispatch(fetchCaseItemRequest())
        GET(`${API_CASES}/${slug}`)
            .then(res => {
                dispatch(fetchCaseItemSuccess(res.body.data))
            })
            .catch(error => {
                dispatch(fetchCaseItemFailure(error))
                dispatch(fetchCaseItemFailure(''))
            })
    }
}

export const FETCH_CASE_ITEM_EQUIPMENTS_REDUCER = (ids) => {
    return async (dispatch) => {
        dispatch(fetchCaseItemEquipmentsRequest())
        const equipments = await fetchEquipments(ids);
        dispatch(fetchCaseItemEquipmentsSuccess(equipments))
        // GET_RAMI(`${API_PRODUCTS}?id=[${ids}]`)
        //     .then(res => {
        //         console.log(res.body)
        //         // const body = [
        //         //     res.body[0],
        //         //     // res.body[1],
        //         //     // res.body[2],
        //         //     // res.body[3],
        //         //     // res.body[4],
        //         // ]
        //         dispatch(fetchCaseItemEquipmentsSuccess(res.body))
        //     })
        //     .catch(error => {
        //         dispatch(fetchCaseItemFailure(error))
        //         dispatch(fetchCaseItemFailure(''))
        //     })
    }
}
