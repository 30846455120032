import { API_RULES } from '../constants/api';
import { GET } from '../functions/superagentSending';


const initialState = {
    loading: false,
    data: {},
    error: '',
};


const FETCH_RENTAL_REQUEST = 'FETCH_RENTAL_REQUEST'
const FETCH_RENTAL_SUCCESS = 'FETCH_RENTAL_SUCCESS'
const FETCH_RENTAL_FAILURE = 'FETCH_RENTAL_FAILURE'

const fetchRentalRequest = () => ({
    type: FETCH_RENTAL_REQUEST
})

const fetchRentalSuccess = data => ({
    type: FETCH_RENTAL_SUCCESS,
    payload: data
})

const fetchRentalFailure = error => ({
    type: FETCH_RENTAL_FAILURE,
    payload: error
})

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_RENTAL_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_RENTAL_SUCCESS:
            return {
                loading: false,
                data: payload,
                error: '',
            }
        case FETCH_RENTAL_FAILURE:
            return {
                ...state,
                loading: false,
                data: {},
                error: payload
            }
        default:
            return state
    }
}

export const FETCH_RENTAL_REDUCER = (slug) => {
    return function (dispatch) {
        dispatch(fetchRentalRequest())
        GET(`${API_RULES}/${slug}`)
            .then(res => {
                dispatch(fetchRentalSuccess(res.body.data))
            })
            .catch(error => {
                dispatch(fetchRentalFailure(error))
                dispatch(fetchRentalFailure(''))
            })
    }
}
