import { API_OFFICES } from '../constants/api';
import { POST, GET_RAMI } from '../functions/superagentSending';


const initialState = {
    loading: false,
    data: {
        data: [],
        offices: [],
        contactOffices: [],
        active: {},
        activeIndex: false,
        city: {},
    },
    sendLoading: false,
    offerSuccess: false,
    contactSuccess: false,
    reclamationSuccess: false,
    errorRami: '',
};


const FETCH_CONTACT_REQUEST = 'FETCH_CONTACT_REQUEST'
const SEND_CONTACT_REQUEST = 'SEND_CONTACT_REQUEST'
const SEND_CONTACT_SUCCESS = 'SEND_CONTACT_SUCCESS'
const FIND_ACTIVE_CITY_SUCCESS = 'FIND_ACTIVE_CITY_SUCCESS'
const ADD_ACTIVE_CONTACT_SUCCESS = 'ADD_ACTIVE_CONTACT_SUCCESS'
const ADD_CITY_CONTACT_SUCCESS = 'ADD_CITY_CONTACT_SUCCESS'
const FETCH_CONTACT_SUCCESS = 'FETCH_CONTACT_SUCCESS'
const FETCH_CONTACT_FAILURE = 'FETCH_CONTACT_FAILURE'
const RESET_CONTACT_SENDING_SUCCESS = 'RESET_CONTACT_SENDING_SUCCESS'

const fetchDataRequest = () => ({
    type: FETCH_CONTACT_REQUEST
})
const resetContactSendingSuccess = () => ({
    type: RESET_CONTACT_SENDING_SUCCESS
})
const fetchDataSuccess = (data) => ({
    type: FETCH_CONTACT_SUCCESS,
    payload: data
})
const findActiveCitySuccess = (data) => ({
    type: FIND_ACTIVE_CITY_SUCCESS,
    payload: data
})

const sendDataSuccess = data => ({
    type: SEND_CONTACT_SUCCESS,
    payload: data
})

const addActiveDataSuccess = (data, offices, activeIndex) => ({
    type: ADD_ACTIVE_CONTACT_SUCCESS,
    payload: {
        data,
        offices,
        activeIndex,
    }
})
const addCityDataSuccess = data => ({
    type: ADD_CITY_CONTACT_SUCCESS,
    payload: data
})

const fetchDataFailure = error => ({
    type: FETCH_CONTACT_FAILURE,
    payload: error
})

const sendContactRequest = () => ({
    type: SEND_CONTACT_REQUEST
})


export default (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_CONTACT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case SEND_CONTACT_REQUEST:
            return {
                ...state,
                sendLoading: true
            }
        case SEND_CONTACT_SUCCESS:
            return {
                ...state,
                errorRami: '',
                loading: false,
                sendLoading: false,
                [payload.id]: payload.value,
            }
        case RESET_CONTACT_SENDING_SUCCESS:
            return {
                ...state,
                errorRami: '',
                loading: false,
                showSuccess: false,
                sendLoading: false,
            }
        case FIND_ACTIVE_CITY_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    active: payload?.active,
                    activeIndex: payload?.activeIndex,
                },
                errorRami: '',
                loading: false,
            }
        case FETCH_CONTACT_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    city: {},
                    // active: {},
                    // activeIndex: 0,
                    ...payload,
                },
                errorRami: '',
                loading: false,
            }
        case ADD_CITY_CONTACT_SUCCESS:
            return {
                data: {
                    ...state.data,
                    city: payload
                },
                errorRami: '',
                loading: false,
            }
        case ADD_ACTIVE_CONTACT_SUCCESS:
            return {
                data: {
                    ...state.data,
                    offices: payload.offices ? payload.offices : state.data.offices,
                    active: payload.data,
                    activeIndex: payload?.activeIndex || 0,
                },
                errorRami: '',
                loading: false,
            }
        case FETCH_CONTACT_FAILURE:
            return {
                loading: false,
                data: {
                    data: [],
                    offices: [],
                    active: {},
                    city: {}
                },
                errorRami: payload
            }
        default:
            return state
    }
}
export const RESET_CONTACT_SENDING_REDUCER = () => (dispatch) => {
    dispatch(resetContactSendingSuccess());
}
export const SEND_CONTACT_REDUCER = (data, API, id) => {
    return function (dispatch) {
        dispatch(sendContactRequest())
        POST(API, data)
            .then(res => {
                dispatch(sendDataSuccess({ id, value: true }))
                dispatch(sendDataSuccess({ id, value: false }))
            })
            .catch(error => {
                dispatch(fetchDataFailure(error))
                // dispatch(fetchDataFailure(''))
            })
    }
}
export const ADD_ACTIVE_CONTACT_REDUCER = (id, offices, index) => {
    return function (dispatch, getState) {
        const { ContactReducer } = getState();
        if (!id || ContactReducer?.data?.active?.id === id) {
            dispatch(addActiveDataSuccess({}, false));
        } else {
            let newActive = {};
            if (offices?.lenght) {
                newActive = offices[0];
            } else {
                newActive = ContactReducer?.data?.offices?.find((office) => office.id === id) || {};
            }
            dispatch(addActiveDataSuccess(newActive, offices, index));
        }
    }
}
export const ADD_ACTIVE_INDEX_CONTACT_REDUCER = (index) => {
    return function (dispatch, getState) {
        const { ContactReducer } = getState();
        const newActive = ContactReducer?.data?.offices?.find((office, i) => i === index) || {};
        dispatch(addActiveDataSuccess(newActive, false, index));
    }
}
export const ADD_CITY_CONTACT_REDUCER = (coords) => {
    return function (dispatch) {
        dispatch(addCityDataSuccess(coords));
    }
}

export const FIND_ACTIVE_CITY_REDUCER = (id) => {
    return function (dispatch, getState) {
        let active = {}
        let activeIndex = 0
        if (id) {
            const { ContactReducer } = getState();
            const offices = ContactReducer?.data?.offices;

            active = offices.find((office, index) => {
                const isTrue = office.id === id;
                if (isTrue) {
                    activeIndex = index
                }
                return isTrue
            })
        }
        dispatch(findActiveCitySuccess({ activeIndex, active }))
        //    active: active || {},
        // activeIndex,
    }
}
export const FETCH_CONTACT_REDUCER = (newData, id, cityName) => {
    return function (dispatch) {
        dispatch(fetchDataRequest())
        if (newData?.data) {
            let offices = modifiData(newData?.data);
            dispatch(fetchDataSuccess({
                newData, offices, contactOffices: offices,
            }))
        } else {
            GET_RAMI(API_OFFICES)
                .then(res => {
                    const data = res.body;
                    let newId = id;
                    let offices = modifiData(data);
                    if (cityName) {
                        offices = sortByCityName(offices, cityName)
                        if (offices?.length)
                            newId = offices[0].id
                    }
                    dispatch(fetchDataSuccess({
                        data, offices, contactOffices: offices,
                    }))
                    dispatch(FIND_ACTIVE_CITY_REDUCER(newId))
                })
                .catch(error => {
                    dispatch(fetchDataFailure(error))
                    // dispatch(fetchDataFailure(''))
                })
        }
    }
}

const sortByCityName = (offices, cityName) => {
    const officesFromCity = [];
    const otherOffices = [];
    if (offices?.length && cityName) {
        for (let i = 0; i < offices.length; i++) {
            if (offices[i].cityName.toLowerCase() === cityName.toLowerCase())
                officesFromCity.push(offices[i])
            else
                otherOffices.push(offices[i])
        }
    }
    return [...officesFromCity, ...otherOffices]
}
const modifiData = (data) => {
    let offices = [];
    data.forEach((region) => {
        region.cities.forEach((city) => {
            offices = [...offices, ...city.offices.map((office) => ({
                ...office,
                lat: parseFloat(office.lat),
                lng: parseFloat(office.lng),
                cityName: city.name,
                cityId: city.id,
                regionName: region.name
            }))]
        })
    })
    return offices
}