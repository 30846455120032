import React from 'react'
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import RouteLinkComponent from './RouteLinkComponent';
import { IconButton } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    iconButton: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(0.5),
    },
    icon: {
        fill: theme.palette.secondary.main,
        width: 22,
        height: 22,

        cursor: 'pointer',
        '& path': {
            fill: theme.palette.primary.main,
            stroke: theme.palette.primary.main,
        }
    },
    title: {
        cursor: 'pointer',
        color: theme.palette.white.main,
        fontSize: 12,
        marginRight: 5,
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    avatar: {
        width: 30,
        height: 30,
    }
}))

const NavigationItemComponent = ({ title, src = '', isAvatar, alt = '', href, route, children, Icon, handleRoute = () => { } }) => {
    const classes = useStyles();

    return (
        <Box display='flex' alignItems='center' >
            <Box lineHeight='0'>
                {Icon ?
                    <RouteLinkComponent to={route ? route : false} href={href ? href : false}>
                        <IconButton size='small' className={classes.iconButton}>
                            <Icon src={src} alt={alt} className={isAvatar ? classes.avatar : classes.icon} />
                        </IconButton>
                    </RouteLinkComponent>

                    : ""}
            </Box>

            {children}
        </Box>
    )
}


export default React.memo(NavigationItemComponent);